import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AccessToken, IDToken, OktaAuth, OktaAuthOptions, SessionObject, TokenManager, TokenResponse, UserClaims } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { AppCacheService } from '../helpers/app-cache.service';
import * as _ from 'lodash';


@Injectable()
export class AuthService {
  private clientConfiguration: OktaAuthOptions = environment.oidc;
  private authClient = new OktaAuth(this.clientConfiguration);

  constructor(
    private router: Router,
    private cacheService: AppCacheService
  ) {
  }

  async checkAuthenticated(): Promise<boolean> {
    return await this.authClient.session.exists();
  }

  async getTokens(): Promise<TokenResponse> {
    if (await this.checkAuthenticated()) {
      this.clientConfiguration.pkce = false;
      const tokenAuthClient = new OktaAuth(this.clientConfiguration);
      const cachedSessionToken = this.cacheService.get('session-token');

      return await tokenAuthClient.token.getWithoutPrompt({
        sessionToken: cachedSessionToken,
        scopes: environment.oidc.scopes
      });
    }
  }

  async getUserClaims(idToken: IDToken, accessToken: AccessToken): Promise<UserClaims> {
    this.clientConfiguration.pkce = false;
    const userInfoClient = new OktaAuth(this.clientConfiguration);
    return await userInfoClient.token.getUserInfo(accessToken, idToken);
  }

  async login(un: string, pw: string, redirectUrl: string): Promise<void | AuthService> {
    // PKCE flow
    return await this.authClient.signInWithCredentials({
      username: un,
      password: pw
    })
      .then((transaction) => {
        if (transaction.status === 'SUCCESS') {
          this.cacheService.remove('session-token');
          const fullRedirectUrl = this.getFullRedirectUrl(redirectUrl);
          const sessionToken = _.cloneDeep(transaction.sessionToken);
          this.cacheService.set('session-token', sessionToken);
          this.authClient.session.setCookieAndRedirect(transaction.sessionToken, fullRedirectUrl);
        } else {
          throw Error('We cannot handle the ' + transaction.status + ' status');
        }
      })

  }

  private getFullRedirectUrl = (redirectPath: string) => environment.frontEndUrl + redirectPath;

  async logout(redirect: string) {
    try {
      await this.authClient.signOut();
      this.cacheService.remove('session-token');
      this.router.navigate([redirect]);
    } catch (err) {
      console.error(err);
    }
  }
}
