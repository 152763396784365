import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UxService } from 'src/app/shared/helpers/ux.service';

@Component({
  selector: 'app-add-litter-announcement',
  templateUrl: './add-litter-announcement.component.html',
  styleUrls: ['./add-litter-announcement.component.css']
})
export class AddLitterAnnouncementComponent implements OnInit {

  constructor(
    private router: Router,
    private uxService: UxService
  ) { }

  ngOnInit() {
    this.uxService.smoothScrollToTop();
  }

  redirectToParent() {
    this.router.navigate(['/manage/content/puppies']);
  }

}
