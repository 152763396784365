import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider-ghost',
  templateUrl: './image-slider-ghost.component.html',
  styleUrls: ['./image-slider-ghost.component.css']
})
export class ImageSliderGhostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
