import { Component, OnInit, TemplateRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MediaListingModel } from '../../../models/media-listing-model';
import { MediaService } from '../../../services/media.service';
import { DirectoryListingModel } from '../../../models/directory-listing-model';
import { environment } from '../../../../../environments/environment';
import { NotificationService } from '../../../helpers/notification.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
const MEDIA_ROOT = environment.media.rootUrl;

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit {

  @Input() fileTypeRestriction: string;
  @Input() modalMode: boolean;
  @Output() mediaSelect: EventEmitter<any> = new EventEmitter();

  root = MEDIA_ROOT;
  accessToken: string;
  selectedMedia;
  totalItems;
  mediaListingsSubscribed = false;
  documents = false;
  images = false;
  audio = false;
  video = false;
  directoryActive = this.documents || this.images || this.audio || this.video;
  allMediaSelected = false;
  downloadDisabled = true;

  deleteDisabled = true;
  deleteModalButtonsDisabled = false;

  moveDisabled = true;

  renameDisabled = true;
  renameModalButtonsDisabled = false;

  newFolderModalButtonsDisabled = false;

  currentPath;
  directoryListing: DirectoryListingModel;

  dropzoneInit = true;
  dropzoneWrapperConfig: DropzoneConfigInterface = {
    paramName: 'files',
    autoReset: 1000,
    errorReset: 1000,
    cancelReset: 1000,
    url: environment.dropzoneWrapper.url,
    headers: {
      Authorization: '',
      'CustomPath': ''
    }
  };

  modalRef: BsModalRef;
  renameTarget;
  newFolderTarget;

  constructor(
    private mediaService: MediaService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.selectedMedia = [];
  }

  /**
   * Gets the content of the current directory and sets a corresponding directory button as active if baseDirectory is used
   * @param path
   * @param baseDirectory
   */
  async getContent(path: string, baseDirectory?: string) {

    // this.dropzoneInit = false;

    if (baseDirectory) {
      switch (baseDirectory) {
        case 'documents':
          this.documents = true;
          this.images = false;
          this.audio = false;
          this.video = false;
          break;
        case 'images':
          this.documents = false;
          this.images = true;
          this.audio = false;
          this.video = false;
          break;
        case 'audio':
          this.documents = false;
          this.images = false;
          this.audio = true;
          this.video = false;
          break;
        case 'video':
          this.documents = false;
          this.images = false;
          this.audio = false;
          this.video = true;
          break;
      }
    }

    this.directoryActive = true;
    this.mediaListingsSubscribed = false;
    this.currentPath = path;
    await this.authService.getTokens()
    .then(async (res) => {
      this.accessToken = res.tokens.accessToken.accessToken;
      this.mediaService.getDirectoryListing(path)
      .subscribe((listing) => {
        this.mediaListingsSubscribed = true;
        this.directoryListing = listing;
        this.totalItems = listing.mediaListings.length;
        this.selectedMedia = this.directoryListing.mediaListings.filter(lst => lst.selected === true);
        this.reInitializeDropzone(path);
      });
    });
  }

  /**
   * Reinitializes the state of dropzone.js so that the component can capture changes to the header
   */
  reInitializeDropzone(newPath: string) {
    this.dropzoneInit = false;
    setTimeout(() => {
      // console.log('newPath before dropzone reinit: ' + newPath);
      this.dropzoneWrapperConfig = {
        paramName: 'files',
        autoReset: 1000,
        errorReset: 1000,
        cancelReset: 1000,
        url: environment.dropzoneWrapper.url,
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          'CustomPath': newPath
        }
      };
      // console.log('newPath after dropzone reinit: ' + newPath);
      this.dropzoneInit = true;
    }, 5000);
  }

  /**
   * Selects or unselects media based on the checkbox event passed in
   * @param e
   */
  selectMedia(e) {

    if (e.target.checked) {
      this.selectedMedia.unshift(e.target);
    } else {
      this.selectedMedia = this.selectedMedia.filter(item => item !== e.target);
    }
    this.selectedMedia = this.directoryListing.mediaListings.filter(lst => lst.selected === true);
    this.setMediaOptions();

    if (this.modalMode) {
      this.mediaSelect.emit(`${this.root}${this.selectedMedia[0].filePath}`);
    }

  }

  /**
   * Selects all current media listed in the directory
   */
  selectAllMedia() {
    if (!this.allMediaSelected) {
      this.directoryListing.mediaListings.forEach(item => {
        item.selected = true;
      });
      this.selectedMedia = this.directoryListing.mediaListings;
      this.allMediaSelected = true;
    } else {
      this.directoryListing.mediaListings.forEach(item => {
        item.selected = false;
      });
      this.selectedMedia = [];
      this.allMediaSelected = false;
    }
    this.setMediaOptions();
  }

  /**
   * Sets all media options based on whether one or multiple media items are selected
   */
  setMediaOptions() {
    this.downloadDisabled = this.selectedMedia === undefined || this.selectedMedia.length > 1 || this.selectedMedia.length === 0;
    this.deleteDisabled = this.selectedMedia === undefined || this.selectedMedia.length === 0;
    this.moveDisabled = this.selectedMedia === undefined || this.selectedMedia.length > 1 || this.selectedMedia.length === 0;
    this.renameDisabled = this.selectedMedia === undefined || this.selectedMedia.length > 1 || this.selectedMedia.length === 0;
  }

  /**
   * Downloads a media object by launching a new browser window/tab and navigating the URL of the media object
   */
  downloadMedia() {
    this.directoryListing.mediaListings
      .filter(item => item.selected)
      .forEach(item => {
        const url = MEDIA_ROOT + item.filePath;
        window.open(url, '_blank');
      });
  }

  /**
  * Opens the 'delete object' modal dialog
  * @param modal
  */
  invokeDeleteModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, { class: 'modal-md modal-dialog-centered' });
  }

  /**
   * Deleted the selected media objects(s)
   */
  deleteMedia() {
    this.deleteModalButtonsDisabled = true;
    this.directoryListing.mediaListings
      .filter(item => item.selected)
      .forEach(item => {
        this.mediaService.deleteItem(item.filePath)
          .subscribe((response) => {
            const successAction = 'Successfully deleted ' + item.name;
            const errorAction = 'Failed to delete file: ';
            if (response.responseBody.feedback === 'Success') {
              this.notificationService.invokeNotificaion('success', successAction);
              this.directoryListing.mediaListings = this.directoryListing.mediaListings.filter(lst => lst !== item);
            } else {
              this.notificationService.invokeNotificaion('error', errorAction + response.responseBody);
            }
            this.deleteModalButtonsDisabled = false;
            this.closeCurrentModal();
          });
      });
  }

  /**
   * Callback event for successful file upload session using dropzone.js wrapper
   */
  async onUploadSuccess(e) {
    // console.log(e);
    const successAction = `Successfully uploaded ${e[0].name}`;
    if (this.currentPath) {
      await this.getContent(this.currentPath);
    }
    this.notificationService.invokeNotificaion('success', successAction);
    if (this.modalMode) {
      this.mediaSelect.emit(e[1].responseBody.files[0].filePath);
    }

  }

  /**
   * Callback event for failed file upload session using dropzone.js wrapper
   */
  async onUploadFailure(e) {
    console.log(e);
    const errorAction = `Failed to upload ${e[0].name}`;
    this.notificationService.invokeNotificaion('error', errorAction);
    if (this.currentPath) {
      await this.getContent(this.currentPath);
    }
  }

  /**
   * Limits this use of special characters
   */
  restrictSpecialCharacters(e) {
    let k;
    k = e.charCode;  // k = event.keyCode;  (Both can be used)

    // Allow -
    if (k === 45) { return; }

    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  /**
   * Opens the 'rename object' modal dialog
   * @param modal
   */
  invokeRenameModal(modal: TemplateRef<any>) {
    this.renameTarget = this.selectedMedia[0].name;
    this.modalRef = this.modalService.show(modal, { class: 'modal-md modal-dialog-centered' });
  }

  /**
   * Saves a file after being renamed and closes the 'rename object' modal dialog
   */
  saveFileRename() {

    this.renameModalButtonsDisabled = true;
    const previousFileNameWithPath = this.selectedMedia[0].filePath;
    const newFileNamewithPath = `${this.directoryListing.path}/${this.renameTarget}`;

    const model = {
      previousFileNameWithExtension: previousFileNameWithPath,
      newFileNameWithExtension: newFileNamewithPath
    };

    this.mediaService.renameMedia(model)
      .subscribe((response) => {
        const successAction = 'Object has been successfully renamed';
        const errorAction = 'Failed to rename object';
        if (response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', successAction);
          this.selectedMedia[0].name = this.renameTarget;
          this.selectedMedia[0].filePath = newFileNamewithPath;
        } else {
          this.notificationService.invokeNotificaion('error', errorAction + response.responseBody);
        }
        this.closeRenameModal();
        this.renameModalButtonsDisabled = false;
      });
  }

  /**
   * closes the 'rename object' modal dialog
   */
  closeRenameModal() {
    this.renameTarget = undefined;
    this.closeCurrentModal();
  }

  /**
   * closes 'delete object' modal dialog
   */
  closeDeleteModal() {
    this.closeCurrentModal();
  }

  /**
   * Opens the 'New Folder' modal dialog
   * @param modal
   */
  invokeNewFolderModal(modal: TemplateRef<any>) {
    this.newFolderTarget = '';
    this.modalRef = this.modalService.show(modal, { class: 'modal-md modal-dialog-centered' });
  }

  saveNewFolder() {

    this.newFolderModalButtonsDisabled = true;
    const model = {
      path: this.currentPath,
      folderName: this.newFolderTarget
    };

    this.mediaService.createFolder(model)
      .subscribe((response) => {
        const successAction = 'Folder was successfully created';
        const errorAction = 'Failed to create folder';
        if (response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', successAction);
          const newFolderListing = {
            name: this.newFolderTarget,
            type: 'folder',
            size: null,
            lastModifiedDateTime: null,
            filePath: `${this.currentPath}/${this.newFolderTarget}`,
            selected: false
          };
          this.directoryListing.mediaListings.unshift(newFolderListing);
        } else {
          this.notificationService.invokeNotificaion('error', errorAction + response.responseBody);
        }
        this.newFolderModalButtonsDisabled = false;
        this.closeCurrentModal();
      });
  }

  /**
   * closes any active modal dialog
   */
  closeCurrentModal() {
    this.modalRef.hide();
  }




  // https://github.com/zefoy/ngx-dropzone-wrapper


}
