import { Component, OnInit, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { LitterAnnouncement } from '../../models/litter-announcement';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LitterAnnouncementService } from '../../services/litter-announcement.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageGalleryModel } from '../../models/image-gallery-model';
import { MediaService } from '../../services/media.service';
import { Dog } from '../../models/dog';
import { DogService } from '../../services/dog.service';
import { UxService } from '../../helpers/ux.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../helpers/notification.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-litter-announcement-editor',
  templateUrl: './litter-announcement-editor.component.html',
  styleUrls: ['./litter-announcement-editor.component.css']
})
export class LitterAnnouncementEditorComponent implements OnInit {

  // Outputs
  @Output() completionEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelledEvent: EventEmitter<any> = new EventEmitter();

  // Opional input of existing litter announcement
  @Input() id?: string;
  existingLitterAnnouncement: boolean;

  // Litter Announcement
  litterAnnouncement: LitterAnnouncement;

  requiredFieldsTabActive = true;
  optionalFieldsTabActive = false;
  postImageTabActive = false;

  imageGalleryOptions: ImageGalleryModel[];
  imageGallerySelectPlaceholder = 'Loading Image Galleries...';

  dogReferenceOptions: Dog[];
  dogReferenceOptionselectPlaceholder = 'Loading Reference Dogs...';

  invalidFieldsOnSubmit = false;

  litterAnnouncementForm = new FormGroup({
    noImageGalleryChoice: new FormControl('', { updateOn: 'change' }),
    title: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    deposit: new FormControl('', Validators.required),
    availableForSale: new FormControl('', Validators.required),
    guarantee: new FormControl('', Validators.required),
    imageGallery: new FormControl(''),
    owner: new FormControl('', Validators.required),
    pedigreeUrl: new FormControl('', Validators.required),
    sire: new FormControl(''),
    noSireChoice: new FormControl('', { updateOn: 'change' }),
    dam: new FormControl(''),
    noDamChoice: new FormControl('', { updateOn: 'change' }),
    litterDue: new FormControl(''),
    whelpDate: new FormControl(''),
    noImageChoice: new FormControl('', { updateOn: 'change' })
  }, { updateOn: 'blur' });

  litterAnnouncementFormTitle = this.litterAnnouncementForm.get('title');
  litterAnnouncementFormPrice = this.litterAnnouncementForm.get('price');
  litterAnnouncementFormDeposit = this.litterAnnouncementForm.get('deposit');
  litterAnnouncementFormAvailableForSale = this.litterAnnouncementForm.get('availableForSale');
  litterAnnouncementFormGuarantee = this.litterAnnouncementForm.get('guarantee');
  litterAnnouncementFormOwner = this.litterAnnouncementForm.get('owner');
  litterAnnouncementFormPedigreeUrl = this.litterAnnouncementForm.get('pedigreeUrl');
  litterAnnouncementFormImageGallery = this.litterAnnouncementForm.get('imageGallery');
  litterAnnouncementFormNoImageGalleryChoice = this.litterAnnouncementForm.get('noImageGalleryChoice');
  litterAnnouncementFormSire = this.litterAnnouncementForm.get('sire');
  litterAnnouncementFormNoSireChoice = this.litterAnnouncementForm.get('noSireChoice');
  litterAnnouncementFormDam = this.litterAnnouncementForm.get('dam');
  litterAnnouncementFormNoDamChoice = this.litterAnnouncementForm.get('noDamChoice');
  litterAnnouncementFormLitterDue = this.litterAnnouncementForm.get('litterDue');
  litterAnnouncementFormWhelpDate = this.litterAnnouncementForm.get('whelpDate');
  litterAnnouncementFormNoImageChoice = this.litterAnnouncementForm.get('noImageChoice');


  modalRef: BsModalRef;

  constructor(
    private litterAnnouncementService: LitterAnnouncementService,
    private dogService: DogService,
    private mediaService: MediaService,
    private uxService: UxService,
    private notificationService: NotificationService,
    private modalService: BsModalService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.litterAnnouncement = new LitterAnnouncement();
    this.litterAnnouncement.imageUrl = '/assets/img/LitterAnnouncementDefault.jpg';
    this.existingLitterAnnouncement = this.id && this.id !== undefined;
    this.loadImageGalleryOptions();
    this.loadReferenceDogOptions();
    if (this.existingLitterAnnouncement) {
      this.loadExistingLitterAnnouncement();
    }
  }

  selectTab(tab: string) {
    switch (tab) {
      case 'requiredFields':
        this.requiredFieldsTabActive = true;
        this.optionalFieldsTabActive = false;
        this.postImageTabActive = false;
        break;
      case 'optionalFields':
        this.requiredFieldsTabActive = false;
        this.optionalFieldsTabActive = true;
        this.postImageTabActive = false;
        this.invalidFieldsOnSubmit = false;
        break;
      case 'postImage':
        this.requiredFieldsTabActive = false;
        this.optionalFieldsTabActive = false;
        this.postImageTabActive = true;
        this.invalidFieldsOnSubmit = false;
        break;
    }
  }

  loadExistingLitterAnnouncement() {
    this.litterAnnouncementService
      .getLitterAnnouncementById(this.id)
      .subscribe((litterAnnouncement) => {
        this.litterAnnouncement = litterAnnouncement;
        this.mapModelToForm();
      });
  }

  loadImageGalleryOptions() {
    this.mediaService
      .getAllImageGalleries()
      .subscribe((response) => {
        this.imageGalleryOptions = response;
        this.imageGallerySelectPlaceholder = '--Choose an Image Gallery--';
      });
  }

  loadReferenceDogOptions() {
    this.dogService
      .getAllReferenceDogs()
      .subscribe((response) => {
        this.dogReferenceOptions = response;
        this.dogReferenceOptionselectPlaceholder = '--Choose a dog--';
      });

  }

  toggleNoImageChoice() {
    if (this.litterAnnouncementFormNoImageChoice.value === true) {
      this.clearImageSelection();
    }
  }

  clearImageSelection() {
    this.litterAnnouncement.imageUrl = '/assets/img/LitterAnnouncementDefault.jpg';
  }

  openImageSelectModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  setImageUrl(url: string) {
    this.litterAnnouncement.imageUrl = url;
    this.closeModal();
  }

  closeModal() {
    this.modalRef.hide();
  }

  saveButtonClick() {
    this.uxService.markFormGroupTouched(this.litterAnnouncementForm);

    // Is the form valid?
    if (this.litterAnnouncementForm.invalid) {
      this.invalidFieldsOnSubmit = true;
      this.selectTab('requiredFields');
      this.uxService.smoothScrollToTop();
      return;
    }

    this.mapFormToModel();

    // Save new or existing and emit status
    if (this.existingLitterAnnouncement) {
      this.litterAnnouncementService.updateLitterAnnouncement(this.litterAnnouncement)
        .subscribe((response) => {
          if (response && response.responseBody.feedback === 'Success') {
            this.notificationService.invokeNotificaion('success', 'Litter Announcement has been successfully updated!');
            this.completionEvent.emit();
          } else {
            this.notificationService.invokeNotificaion('error', 'Unable to update Litter Announcement!');
          }
        });
    } else {
      this.litterAnnouncementService.addLitterAnnouncement(this.litterAnnouncement)
        .subscribe((response) => {
          if (response && response.responseBody.feedback === 'Success') {
            this.notificationService.invokeNotificaion('success', 'Litter Announcement has been successfully added!');
            this.completionEvent.emit();
          } else {
            this.notificationService.invokeNotificaion('error', 'Unable to add Litter Announcement!');
          }
        });
    }
  }

  mapModelToForm() {
    this.litterAnnouncementForm.controls['title'].setValue(this.litterAnnouncement.title);
    this.litterAnnouncementForm.controls['price'].setValue(this.litterAnnouncement.price);
    this.litterAnnouncementForm.controls['deposit'].setValue(this.litterAnnouncement.deposit);
    this.litterAnnouncementForm.controls['availableForSale'].setValue(this.litterAnnouncement.availableForSale);
    this.litterAnnouncementForm.controls['guarantee'].setValue(this.litterAnnouncement.guarantee);
    this.litterAnnouncementForm.controls['owner'].setValue(this.litterAnnouncement.owner);
    this.litterAnnouncementForm.controls['pedigreeUrl'].setValue(this.litterAnnouncement.pedigreeUrl);

    if (this.litterAnnouncement.imageGalleryId !== undefined && this.litterAnnouncement.imageGalleryId !== null) {
      this.litterAnnouncementForm.controls['noImageGalleryChoice'].setValue(false);
      this.litterAnnouncementForm.controls['imageGallery'].setValue(this.litterAnnouncement.imageGalleryId);
    } else {
      this.litterAnnouncementForm.controls['noImageGalleryChoice'].setValue(true);
    }

    if (this.litterAnnouncement.sireId !== undefined && this.litterAnnouncement.sireId !== null) {
      this.litterAnnouncementForm.controls['noSireChoice'].setValue(false);
      this.litterAnnouncementForm.controls['sire'].setValue(this.litterAnnouncement.sireId);
    } else {
      this.litterAnnouncementForm.controls['noSireChoice'].setValue(true);
    }

    if (this.litterAnnouncement.damId !== undefined && this.litterAnnouncement.damId !== null) {
      this.litterAnnouncementForm.controls['noDamChoice'].setValue(false);
      this.litterAnnouncementForm.controls['dam'].setValue(this.litterAnnouncement.damId);
    } else {
      this.litterAnnouncementForm.controls['noDamChoice'].setValue(true);
    }

    if (this.litterAnnouncement.dueDate !== undefined && this.litterAnnouncement.dueDate !== null) {
      const litterDueFormatted = this.datePipe.transform(new Date(this.litterAnnouncement.dueDate), 'yyyy-MM-dd');
      this.litterAnnouncementForm.controls['litterDue'].setValue(litterDueFormatted);
    }

    if (this.litterAnnouncement.whelpDate !== undefined && this.litterAnnouncement.whelpDate !== null) {
      const whelpDateFormatted = this.datePipe.transform(new Date(this.litterAnnouncement.whelpDate), 'yyyy-MM-dd');
      this.litterAnnouncementForm.controls['whelpDate'].setValue(whelpDateFormatted);
    }

    if (this.litterAnnouncement.imageUrl !== '/assets/img/LitterAnnouncementDefault.jpg') {
      this.litterAnnouncementForm.controls['noImageChoice'].setValue(false);
    } else {
      this.litterAnnouncementForm.controls['noImageChoice'].setValue(true);
    }

  }

  mapFormToModel() {
    this.litterAnnouncement.title = this.litterAnnouncementFormTitle.value;
    this.litterAnnouncement.price = this.litterAnnouncementFormPrice.value;
    this.litterAnnouncement.deposit = this.litterAnnouncementFormDeposit.value;
    this.litterAnnouncement.availableForSale = this.litterAnnouncementFormAvailableForSale.value;
    this.litterAnnouncement.guarantee = this.litterAnnouncementFormGuarantee.value;
    this.litterAnnouncement.owner = this.litterAnnouncementFormOwner.value;
    this.litterAnnouncement.pedigreeUrl = this.litterAnnouncementFormPedigreeUrl.value;



    // Value or null for the following fields
    if (this.litterAnnouncementFormLitterDue.value !== null &&
      this.litterAnnouncementFormLitterDue.value !== undefined &&
      this.litterAnnouncementFormLitterDue.value !== '') {
      this.litterAnnouncement.dueDate = this.litterAnnouncementFormLitterDue.value;
    } else {
      this.litterAnnouncement.dueDate = null;
    }

    if (this.litterAnnouncementFormWhelpDate.value !== null &&
      this.litterAnnouncementFormWhelpDate.value !== undefined &&
      this.litterAnnouncementFormWhelpDate.value !== '') {
      this.litterAnnouncement.whelpDate = this.litterAnnouncementFormWhelpDate.value;
    } else {
      this.litterAnnouncement.whelpDate = null;
    }

    if (this.litterAnnouncementFormNoImageGalleryChoice.value === true ||
      (this.litterAnnouncementFormImageGallery.value === null ||
        this.litterAnnouncementFormImageGallery.value === undefined ||
        this.litterAnnouncementFormImageGallery.value === '')) {
      this.litterAnnouncement.imageGalleryId = null;
    } else {
      this.litterAnnouncement.imageGalleryId = this.litterAnnouncementFormImageGallery.value;
    }

    if (this.litterAnnouncementFormNoSireChoice.value === true ||
      (this.litterAnnouncementFormSire.value === null ||
        this.litterAnnouncementFormSire.value === undefined ||
        this.litterAnnouncementFormSire.value === '')) {
      this.litterAnnouncement.sireId = null;
    } else {
      this.litterAnnouncement.sireId = this.litterAnnouncementFormSire.value;
    }

    if (this.litterAnnouncementFormNoDamChoice.value === true ||
      (this.litterAnnouncementFormDam.value === null ||
        this.litterAnnouncementFormDam.value === undefined ||
        this.litterAnnouncementFormDam.value === '')) {
      this.litterAnnouncement.damId = null;
    } else {
      this.litterAnnouncement.damId = this.litterAnnouncementFormDam.value;
    }
  }

  cancelButtonClick() {
    // FUTURE: implement 'Are you sure' notification
    this.cancelledEvent.emit();
  }

}
