import { Component, OnInit, Input } from '@angular/core';
import { CarouselImageModel } from '../../../models/carousel-image-model';
import 'hammerjs';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-image-gallery-carousel',
  templateUrl: './image-gallery-carousel.component.html',
  styleUrls: ['./image-gallery-carousel.component.css']
})
export class ImageGalleryCarouselComponent implements OnInit {

  @Input() gallery: CarouselImageModel[];

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit() {

    // https://github.com/lukasz-galka/ngx-gallery
    // In the future, gallery options could be passed in through the parent gallery
    // object from the API and through this component to capture and apply options
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

  }

}
