import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-image-gallery',
  templateUrl: './update-image-gallery.component.html',
  styleUrls: ['./update-image-gallery.component.css']
})
export class UpdateImageGalleryComponent implements OnInit {

  galleryId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.galleryId = this.route.snapshot.paramMap.get('id');
  }

  redirectToManager() {
    // redirect to image gallery manager
    this.router.navigate(['/manage/media/image-galleries']);
  }

}
