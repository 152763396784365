import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PageModel } from '../models/page-model';
import { HttpClient, HttpParams } from '@angular/common/http';

const API_URL = environment.apiUrl;
const pagesUrl = `${API_URL}/pages`;

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private http: HttpClient) { }

  /**
   * Retrieves a pages by its assigned Id
   * @param id
   */
  public getPageById(id: string): Observable<PageModel> {
    const params = new HttpParams().set('id', id);
    return this.http.get<PageModel>(pagesUrl, { params: params });
  }

  /**
   * Updates a pages by passing in most current data
   * @param model
   */
  public updatePage(model: any): Observable<any> {
    return this.http.patch<any>(pagesUrl, model);
  }
}
