import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';



import { HttpClient, HttpParams } from '@angular/common/http';
import { Dog } from '../models/dog';
import { TrainedDog } from '../models/trained-dog';
import { StudDog } from '../models/stud-dog';
import { GirlDog } from '../models/girl-dog';

const API_URL = environment.apiUrl;
const dogsUrl = `${API_URL}/dogs`;
const trainedDogsUrl = `${API_URL}/trained-dogs`;
const studDogsUrl = `${API_URL}/stud-dogs`;
const girlDogsUrl = `${API_URL}/girl-dogs`;

@Injectable({
  providedIn: 'root'
})
export class DogService {

  constructor(private http: HttpClient) { }

  /**
  * Retrieves a single dog based on the db assigned identifyer
  * @param id
  */
  public getDogById(id: string): Observable<Dog> {
    const params = new HttpParams().set('id', id);
    return this.http.get<Dog>(dogsUrl, { params });
  }

  /**
  * Retrieves all dogs
  */
  public getAllDogs(): Observable<Dog[]> {
    return this.http.get<Dog[]>(dogsUrl);
  }

  /**
  * Retrieves all public dogs
  */
  public getAllPublicDogs(): Observable<Dog[]> {
    const params = new HttpParams().set('public', 'true');
    return this.http.get<Dog[]>(dogsUrl, { params });
  }

  /**
  * Retrieves all reference dogs
  */
  public getAllReferenceDogs(): Observable<Dog[]> {
    const params = new HttpParams().set('reference', 'true');
    return this.http.get<Dog[]>(dogsUrl, { params });
  }

  /**
   * Retrieves a single Trained Dog based on the db assigned identifier
   * @param id
   */
  public getTrainedDogById(id: string): Observable<TrainedDog> {
    const params = new HttpParams().set('id', id);
    return this.http.get<TrainedDog>(trainedDogsUrl, { params });
  }

  /**
  * Retrieves all Trained Dogs
  */
  public getAllTrainedDogs(): Observable<TrainedDog[]> {
    return this.http.get<TrainedDog[]>(trainedDogsUrl);
  }

  /**
  * Retrieves all public Trained Dogs
  */
  public getAllPublicTrainedDogs(): Observable<TrainedDog[]> {
    const params = new HttpParams().set('public', 'true');
    return this.http.get<TrainedDog[]>(trainedDogsUrl, { params });
  }

  /**
  * Adds a single Trained Dog
  * @param model
  */
  public addTrainedDog(model: TrainedDog): Observable<any> {
    return this.http.post<TrainedDog>(trainedDogsUrl, model);
  }

  /**
  * Updates a Trained Dog
  * @param model
  */
  public updateTrainedDog(model: any): Observable<any> {
    return this.http.put<TrainedDog>(trainedDogsUrl, model);
  }

  /**
  * Removes an existing Trained Dog
  */
  public removeTrainedDog(id: string): Observable<any> {
    const param = new HttpParams().set('id', id);
    return this.http.delete<TrainedDog>(trainedDogsUrl, { params: param });
  }

  /**
 * Retrieves a single Stud Dog based on the db assigned identifier
 * @param id
 */
  public getStudDogById(id: string): Observable<StudDog> {
    const params = new HttpParams().set('id', id);
    return this.http.get<StudDog>(studDogsUrl, { params });
  }

  /**
  * Retrieves all Stud Dogs
  */
  public getAllStudDogs(): Observable<StudDog[]> {
    return this.http.get<StudDog[]>(studDogsUrl);
  }

  /**
  * Retrieves all public Stud Dogs
  */
  public getAllPublicStudDogs(): Observable<StudDog[]> {
    const params = new HttpParams().set('public', 'true');
    return this.http.get<StudDog[]>(studDogsUrl, { params });
  }

  /**
  * Adds a single Stud Dog
  * @param model
  */
  public addStudDog(model: StudDog): Observable<any> {
    return this.http.post<StudDog>(studDogsUrl, model);
  }

  /**
  * Updates a Stud Dog
  * @param model
  */
  public updateStudDog(model: any): Observable<any> {
    return this.http.put<StudDog>(studDogsUrl, model);
  }

  /**
  * Removes an existing Stud Dog
  */
  public removeStudDog(id: string): Observable<any> {
    const param = new HttpParams().set('id', id);
    return this.http.delete<StudDog>(studDogsUrl, { params: param });
  }

  /**
 * Retrieves a single Girl Dog based on the db assigned identifier
 * @param id
 */
  public getGirlDogById(id: string): Observable<GirlDog> {
    const params = new HttpParams().set('id', id);
    return this.http.get<GirlDog>(girlDogsUrl, { params });
  }

  /**
  * Retrieves all Girl Dogs
  */
  public getAllGirlDogs(): Observable<GirlDog[]> {
    return this.http.get<GirlDog[]>(girlDogsUrl);
  }

  /**
  * Retrieves all public Girl Dogs
  */
  public getAllPublicGirlDogs(): Observable<GirlDog[]> {
    const params = new HttpParams().set('public', 'true');
    return this.http.get<GirlDog[]>(girlDogsUrl, { params });
  }

  /**
  * Adds a single Girl Dog
  * @param model
  */
  public addGirlDog(model: GirlDog): Observable<any> {
    return this.http.post<GirlDog>(girlDogsUrl, model);
  }

  /**
  * Updates a Girl Dog
  * @param model
  */
  public updateGirlDog(model: any): Observable<any> {
    return this.http.put<GirlDog>(girlDogsUrl, model);
  }

  /**
  * Removes an existing Girl Dog
  */
  public removeGirlDog(id: string): Observable<any> {
    const param = new HttpParams().set('id', id);
    return this.http.delete<GirlDog>(girlDogsUrl, { params: param });
  }
}
