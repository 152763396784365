import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageService } from '../../services/page.service';
import { NotificationService } from '../../helpers/notification.service';
import { PageModel } from '../../models/page-model';

@Component({
  selector: 'app-header-card',
  templateUrl: './header-card.component.html',
  styleUrls: ['./header-card.component.css']
})
export class HeaderCardComponent implements OnInit {

  @Input() cmsMode: boolean;
  @Input() pageModel: PageModel;

  constructor(
    private pageService: PageService,
    private notificationService: NotificationService) { }

  ngOnInit() {
  }

  /**
   * Updates header card title
   */
  saveHeaderCardTitle(headerCardTitle: string) {

    // Patch model
    const cardPatch = {
      'id': this.pageModel.id,
      'headerCardTitle': headerCardTitle
    };

    this.updatePage(cardPatch);
  }

  /**
 * Updates header card sub title
 */
  saveHeaderCardSubTitle(headerCardSubTitle: string) {

    // Patch model
    const cardPatch = {
      'id': this.pageModel.id,
      'headerCardSubTitle': headerCardSubTitle
    };

    this.updatePage(cardPatch);
  }

  updatePage(model: any) {
    this.pageService.updatePage(model)
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Page Header Card has been successfully updated');
        } else {
          this.notificationService.invokeNotificaion('error', 'There was an error while attempting to update page Header Card details');
        }
      });
  }

}
