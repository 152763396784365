import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

  }

  // https://github.com/scttcper/ngx-toastr#faq

  /**
   * Invokes a new instance of NGX Toaster
   * @param type success, error, error-custom or info
   * @param action a description of the action that was taken or information that is needed
   * @param customTitle a custom title that overrides the conventional 'Success:', 'Error:' or 'Info:' titles
   */
  invokeNotificaion(type: string, action: string, customTitle?: string) {

    setTimeout(() => {
      const options = {
        positionClass: 'toast-bottom-center',
        onActivateTick: true
      };

      switch (type) {
        case 'success':
          this.toastr.success(action, 'Success:', options);
          break;
        case 'error':
          this.toastr.error(action, 'Error:', options);
          break;
        case 'error-custom':
          this.toastr.error(action, customTitle, options);
          break;
        case 'info':
          this.toastr.info(action, 'Info:', options);
          break;
      }
    });

  }
}
