import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dog-post-ghost',
  templateUrl: './dog-post-ghost.component.html',
  styleUrls: ['./dog-post-ghost.component.css']
})
export class DogPostGhostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
