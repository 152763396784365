import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { PageMetaTagModel } from '../models/page-meta-tag-model';
import { environment } from 'src/environments/environment.prod';

const SOCIAL_CHANNELS = environment.socialChannels;

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private meta: Meta,
    @Inject(DOCUMENT) private doc,
    private titleService: Title
  ) { }

  public setMetaTags(metaTags: PageMetaTagModel) {
    this.meta.addTags([
      { name: 'keywords', content: metaTags.keywordsMeta },
      { name: 'author', content: metaTags.authorMeta },
      { name: 'description', content: metaTags.descriptionMeta },
      { name: 'fb:app_id', content: SOCIAL_CHANNELS.facebook.app_id },
      { name: 'og:title', content: metaTags.ogTitleMeta },
      { name: 'og:image', content: 'https://media.nlbclongview.org/images/SEO/Logo%20Full.jpg' },
      { name: 'og:description', content: metaTags.ogDescriptionMeta },
      { name: 'og:url', content: metaTags.ogUrlMeta },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: SOCIAL_CHANNELS.twitter.site }
    ]);
  }

  public setCononicalUrl(url: string) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', url);
  }
}

