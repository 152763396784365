import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { UxService } from 'src/app/shared/helpers/ux.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginClicked = false;
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  }, { updateOn: 'blur' });
  loginFormUsername: AbstractControl;
  loginFormPassword: AbstractControl;
  public loginInvalid: boolean;
  private returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private noticficationsService: NotificationService
  ) {
  }

  async ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/manage';

    this.loginFormUsername = this.loginForm.get('username');
    this.loginFormPassword = this.loginForm.get('password');

    if (await this.authService.checkAuthenticated()) {
      await this.router.navigate([this.returnUrl]);
    }
  }

  async onSubmit() {

    this.loginInvalid = false;
    this.loginClicked = true;
    if (this.loginForm.valid) {
      try {
        const username = this.loginFormUsername.value;
        const password = this.loginFormPassword.value;
        await this.authService.login(username, password, this.returnUrl);
      } catch (err) {
        console.log(err);
        this.noticficationsService.invokeNotificaion('error-custom', 'Check your username / password and try again', 'Login Failed:');
        this.loginInvalid = true;
        this.loginClicked = false;
      }
    } else {
      this.loginClicked = false;
    }
  }

}
