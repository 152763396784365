// Core Dependencies
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Auth Guard
import { AuthGuard } from './shared/guards/auth.guard';

// Components
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { BrowseComponent } from './public/browse/browse.component';
import { HomeComponent } from './public/home/home.component';
import { AboutComponent } from './public/about/about.component';
import { ContactComponent } from './public/contact/contact.component';
import { PuppiesComponent } from './public/puppies/puppies.component';
import { TrainedDogsComponent } from './public/trained-dogs/trained-dogs.component';
import { StudDogsComponent } from './public/stud-dogs/stud-dogs.component';
import { OurGirlsComponent } from './public/our-girls/our-girls.component';
import { TrainingServicesComponent } from './public/training-services/training-services.component';
import { CmsHomeComponent } from './cms/content/home/home.component';
import { CmsAboutComponent } from './cms/content/about/about.component';
import { CmsPuppiesComponent } from './cms/content/puppies/puppies.component';
import { CmsTrainedDogsComponent } from './cms/content/trained-dogs/trained-dogs.component';
import { CmsStudDogsComponent } from './cms/content/stud-dogs/stud-dogs.component';
import { CmsOurGirlsComponent } from './cms/content/our-girls/our-girls.component';
import { CmsContactComponent } from './cms/content/contact/contact.component';
import { MediaObjectsComponent } from './cms/media/media-objects/media-objects.component';
import { ImageGalleriesComponent } from './cms/media/image-galleries/image-galleries.component';
import { AccountManagementComponent } from './cms/users/account-management/account-management.component';
import { MailingListComponent } from './cms/users/subscriptions/mailing-list/mailing-list.component';
import { DashboardComponent } from './cms/dashboard/dashboard.component';
import { LoginComponent } from './authentication/login/login.component';
import { ManageComponent } from './cms/manage/manage.component';
import { CmsTrainingServicesComponent } from './cms/content/training-services/training-services.component';
import { AddImageGalleryComponent } from './cms/media/add-image-gallery/add-image-gallery.component';
import { UpdateImageGalleryComponent } from './cms/media/update-image-gallery/update-image-gallery.component';
import { AddLitterAnnouncementComponent } from './cms/content/add-litter-announcement/add-litter-announcement.component';
import { UpdateLitterAnnouncementComponent } from './cms/content/update-litter-announcement/update-litter-announcement.component';
import { AddDogComponent } from './cms/content/add-dog/add-dog.component';
import { UpdateDogComponent } from './cms/content/update-dog/update-dog.component';
import { UnknownErrorComponent } from './errors/unknown-error/unknown-error.component';
import { MailingListSubscribeComponent } from './public/subscriptions/mailing-list-subscribe/mailing-list-subscribe.component';
import { MailingListUnsubscribeComponent } from './public/subscriptions/mailing-list-unsubscribe/mailing-list-unsubscribe.component';

const appRoutes: Routes = [

  // Legacy route redirects
  { path: 'home', redirectTo: 'home', pathMatch: 'full' },
  { path: 'puppies', redirectTo: 'browse/sales/puppies', pathMatch: 'full' },
  { path: 'trained-dogs', redirectTo: 'browse/sales/trained-dogs', pathMatch: 'full' },
  { path: 'stud-dogs', redirectTo: 'browse/sales/stud-dogs', pathMatch: 'full' },
  { path: 'our-girls', redirectTo: 'browse/sales/our-girls', pathMatch: 'full' },
  { path: 'info', redirectTo: 'browse/sales/about', pathMatch: 'full' },
  { path: 'links', redirectTo: 'browse/sales/about', pathMatch: 'full' },
  { path: 'contact', redirectTo: 'browse/contact', pathMatch: 'full' },

  // SEO public routes
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: 'sales/puppies', redirectTo: 'browse/sales/puppies', pathMatch: 'full' },
  { path: 'sales/trained-dogs', redirectTo: 'browse/sales/trained-dogs', pathMatch: 'full' },
  { path: 'sales/stud-dogs', redirectTo: 'browse/sales/stud-dogs', pathMatch: 'full' },
  { path: 'sales/our-girls', redirectTo: 'browse/sales/our-girls', pathMatch: 'full' },
  { path: 'services/training', redirectTo: 'browse/services/training', pathMatch: 'full' },
  { path: 'about', redirectTo: 'browse/about', pathMatch: 'full' },
  { path: 'contact', redirectTo: 'browse/contact', pathMatch: 'full' },

  // Public routes

  { path: 'home', component: HomeComponent, pathMatch: 'full' },

  {
    path: 'browse', component: BrowseComponent, children: [
      { path: 'sales/puppies', component: PuppiesComponent, pathMatch: 'full' },
      { path: 'sales/trained-dogs', component: TrainedDogsComponent, pathMatch: 'full' },
      { path: 'sales/stud-dogs', component: StudDogsComponent, pathMatch: 'full' },
      { path: 'sales/our-girls', component: OurGirlsComponent, pathMatch: 'full' },
      { path: 'services/training', component: TrainingServicesComponent, pathMatch: 'full' },
      { path: 'about', component: AboutComponent, pathMatch: 'full' },
      { path: 'contact', component: ContactComponent, pathMatch: 'full' },
      { path: 'mailing-list', component: MailingListSubscribeComponent, pathMatch: 'full' },
      { path: 'mailing-list/unsubscribe', component: MailingListUnsubscribeComponent, pathMatch: 'full' },

      // 404 route
      { path: '**', component: PageNotFoundComponent }
    ]
  },

  // Authentication
  { path: 'login', component: LoginComponent, pathMatch: 'full' },

  // Errors
  { path: 'unknown-error', component: UnknownErrorComponent, pathMatch: 'full' },

  // CMS Routes
  { path: 'manage', redirectTo: 'manage/dashboard', pathMatch: 'full' },
  {
    path: 'manage', component: ManageComponent,
    canActivate: [AuthGuard],
    children: [

      { path: 'dashboard', component: DashboardComponent, pathMatch: 'full' },

      { path: 'content/home', component: CmsHomeComponent, pathMatch: 'full' },
      { path: 'content/puppies', component: CmsPuppiesComponent, pathMatch: 'full' },
      { path: 'content/puppies/add', component: AddLitterAnnouncementComponent, pathMatch: 'full' },
      { path: 'content/puppies/update/:id', component: UpdateLitterAnnouncementComponent, pathMatch: 'full' },
      { path: 'content/trained-dogs', component: CmsTrainedDogsComponent, pathMatch: 'full' },
      { path: 'content/stud-dogs', component: CmsStudDogsComponent, pathMatch: 'full' },
      { path: 'content/our-girls', component: CmsOurGirlsComponent, pathMatch: 'full' },
      { path: 'content/dog/:type/add', component: AddDogComponent, pathMatch: 'full' },
      { path: 'content/dog/:type/update/:id', component: UpdateDogComponent, pathMatch: 'full' },
      { path: 'content/training-services', component: CmsTrainingServicesComponent, pathMatch: 'full' },
      { path: 'content/about', component: CmsAboutComponent, pathMatch: 'full' },
      { path: 'content/contact', component: CmsContactComponent, pathMatch: 'full' },

      { path: 'media/media-objects', component: MediaObjectsComponent, pathMatch: 'full' },
      { path: 'media/image-galleries', component: ImageGalleriesComponent, pathMatch: 'full' },
      { path: 'media/image-galleries/add', component: AddImageGalleryComponent, pathMatch: 'full' },
      { path: 'media/image-galleries/update/:id', component: UpdateImageGalleryComponent, pathMatch: 'full' },

      { path: 'users/account-management', component: AccountManagementComponent, pathMatch: 'full' },
      { path: 'users/subscriptions/mailing-list', component: MailingListComponent, pathMatch: 'full' },

      // 404 route
      { path: '**', component: PageNotFoundComponent }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
