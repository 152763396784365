import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleAsync(request, next));
    }

    async handleAsync(req: HttpRequest<any>, next: HttpHandler) {
        const tokenData = await this.authService.getTokens();
        if (tokenData) {
            const authReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenData.tokens.accessToken.accessToken}`
                }
            })
            return next.handle(authReq).toPromise()
        }
        return next.handle(req).toPromise();
    }
}
