export const environment = {
  production: true,
  environmentName: 'Production',
  apiUrl: '/api',
  frontEndUrl: 'https://moccasincreek.net',
  basePageTitle: ' | Moccasin Creek Kennel',
  oidc: {
    issuer: 'https://dev-585272.okta.com/oauth2/default',
    redirectUri: 'https://moccasincreek.net/manage',
    clientId: '0oa9hwx6ryYAt17Uf356',
    scopes: ['openid', 'profile', 'email'],
    responseType: ['id_token', 'token']
  },
  media: {
    rootUrl: 'https://media.moccasincreek.net/prod/'
  },
  socialChannels: {
    facebook: {
      url: 'https://www.facebook.com/moccasincreek.net/',
      app_id: '445589342754196'
    },
    twitter: {
      url: 'https://twitter.com/MCreekKennel',
      site: 'MCreekKennel'
    },
    instagram: {
      url: 'https://www.instagram.com/moccasincreekkennel/'
    },
    youtube: {
      url: 'https://www.youtube.com/channel/UCz6RxBa6X0Dx_LhXsgap4RA'
    }
  },
  dropzoneWrapper: {
    url: 'https://moccasincreek.net/api/media',
    paramName: 'files'
  },
  tinyMce: {
    init: {
      skin_url: '/assets/css/tinymce/skins/ui/oxide',
      plugins: ['quickbars link iconinsert'],
      extended_valid_elements: 'i[*],span[*],a[*]',
      valid_children: '+a[i]',
      toolbar: false,
      menubar: false,
      inline: true,
      quickbars_selection_toolbar: 'bold italic underline | styleselect | removeformat | iconinsert | link',
      contextmenu: 'link iconinsert',
      style_formats_merge: false,
      formats: {
        greyButton: { inline: 'a', classes: 'btn btn-sm btn-light rounded-0 m-1 border' }
      },
      style_formats: [
        { title: 'Grey Button', format: 'greyButton' },
      ],
      quickbars_insert_toolbar: false,
      branding: false
    }
  },
  recaptcha: {
    siteKey: '6LehgrwUAAAAAEh--EcG8X683NOCS7te9B1lnSXU',
    secretKey: '6LehgrwUAAAAAO0EElMk0ojurcYHgL4gjf5volIV'
  }
};
