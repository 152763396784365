export class CarouselImageModel {
    id: number;
    selected?: boolean;
    small: string;
    medium: string;
    big: string;
    label: string;
    description: string;
    url: string;
    imageGalleryId: number;
}
