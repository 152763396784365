import { Component, OnInit, TemplateRef } from '@angular/core';
import { MediaService } from '../../../services/media.service';
import { NotificationService } from '../../../helpers/notification.service';
import { ImageGalleryModel } from '../../../models/image-gallery-model';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-image-gallery-manager',
  templateUrl: './image-gallery-manager.component.html',
  styleUrls: ['./image-gallery-manager.component.css']
})
export class ImageGalleryManagerComponent implements OnInit {

  imageGalleries: ImageGalleryModel[];
  imageGalleriesSubscription = false;
  selectedGallery: number;

  modalRef: BsModalRef;

  constructor(
    private router: Router,
    private mediaService: MediaService,
    private notificationService: NotificationService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.loadImageGalleries();
  }

  /**
   * Loads all image galleries
   */
  loadImageGalleries() {

    this.imageGalleriesSubscription = false;

    // Get Image Galleries
    this.mediaService
      .getAllImageGalleries()
      .subscribe((imageGalleries) => {
        this.imageGalleries = imageGalleries;
        this.imageGalleriesSubscription = true;
      });
  }

  /**
   *
   * @param id Removes an image gallery
   */
  removeImageGallery(id: number) {

    this.mediaService.removeImageGallery(id.toString())
      .subscribe(
        (result) => {
          const successAction = 'Successfully removed Image Gallery';
          const errorAction = 'Failed to remove Image Gallery: ';
          if (result.body.feedback === 'Success') {
            this.imageGalleries = this.imageGalleries.filter(imgGal => imgGal.id !== id);
            this.notificationService.invokeNotificaion('success', successAction);
          } else {
            this.notificationService.invokeNotificaion('error', errorAction + result.body);
          }
        }
      );

  }

  /**
   * Selects or unselects an image gallery based on the checkbox event passed in
   * @param id
   */
  selectGallery(id: number) {
    const target = this.imageGalleries.find(gal => gal.id === id);

    if (target) {
      const unselected = this.imageGalleries.filter(gal => gal !== target);
      unselected.forEach(gal => {
        gal.selected = false;
      });

      if (target.selected) {
        this.selectedGallery = id;
      } else {
        this.selectedGallery = undefined;
      }

    }
  }

  onImageGalleryEditClick() {
    // redirect to editor
    this.router.navigate(['/manage/media/image-galleries/update', this.selectedGallery]);
  }

  onImageGalleryRemoveClick(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, { class: 'modal-md', ignoreBackdropClick: true });
  }

  onImageGalleryRemoveModalConfirm() {
    this.removeGallery();
    this.modalRef.hide();
  }

  removeGallery() {
    // remove from list
    const existingGallery = this.imageGalleries.find(gal => gal.id === this.selectedGallery);
    this.imageGalleries = this.imageGalleries.filter(gal => gal !== existingGallery);

    // remove from db via API
    this.mediaService.removeImageGallery(existingGallery.id.toString())
      .subscribe((response) => {
        if (response.responseBody.feedback === 'Success') {
          // success
          this.notificationService.invokeNotificaion('success', 'Image Gallery has been successfully deleted');
        } else {
          // failure
          this.notificationService.invokeNotificaion('error', 'There was an error while attempting to delete the Image Gallery');
        }
      });
  }

  onImageGalleryRemoveModalCancel() {
    this.imageGalleries.find(gal => gal.id === this.selectedGallery).selected = false;
    this.selectedGallery = undefined;
    this.modalRef.hide();
  }

}
