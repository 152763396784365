import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-card-ghost',
  templateUrl: './header-card-ghost.component.html',
  styleUrls: ['./header-card-ghost.component.scss']
})
export class HeaderCardGhostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
