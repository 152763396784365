export class LitterAnnouncement {
    id = 0;
    title: string;
    imageUrl: string;
    imageGalleryId: number;
    pedigreeUrl: string;
    availableForSale: string;
    dueDate: string;
    whelpDate: string;
    price: number;
    deposit: number;
    guarantee: string;
    owner: string;
    sireId: number;
    sireTitle: string;
    damId: number;
    damTitle: string;
    public: boolean;
}
