import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { LitterAnnouncement } from '../../../models/litter-announcement';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Dog } from 'src/app/shared/models/dog';
import { CarouselImageModel } from 'src/app/shared/models/carousel-image-model';
import { LitterAnnouncementService } from 'src/app/shared/services/litter-announcement.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';
import { MediaService } from 'src/app/shared/services/media.service';
import { DogService } from 'src/app/shared/services/dog.service';

@Component({
  selector: 'app-litter-announcement',
  templateUrl: './litter-announcement.component.html',
  styleUrls: ['./litter-announcement.component.css']
})
export class LitterAnnouncementComponent implements OnInit {

  @Input() litterAnnouncement: LitterAnnouncement;
  @Input() cmsMode: boolean;
  @Output() removalEvent: EventEmitter<any> = new EventEmitter();

  modalRef: BsModalRef;

  sireModel: Dog;
  damModel: Dog;
  galleryImageSlides: CarouselImageModel[];
  gallerySlideInterval = 5000;
  galleryActiveSlideIndex = 0;

  postEmailSubject: string;
  postEmailBody: string;
  postEmailBodyEncoded: string;
  postEmailHyperlink: string;

  constructor(
    private modalService: BsModalService,
    private litterAnnouncementService: LitterAnnouncementService,
    private dogService: DogService,
    private mediaService: MediaService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {

    this.verifyImageUrl();

    this.postEmailSubject = encodeURI(this.litterAnnouncement.title);

    this.postEmailBody = `Hello, I am inquiring about the availability of the litter from your post ` +
      `titled "${this.litterAnnouncement.title}"`;

    this.postEmailBodyEncoded = encodeURI(this.postEmailBody);

    this.postEmailHyperlink = `mailto:danny.littlejohn@moccasincreek.net?` +
      `subject=${this.postEmailSubject}&body=${this.postEmailBodyEncoded}`;
  }

  verifyImageUrl() {
    if (!this.litterAnnouncement.imageUrl || this.litterAnnouncement.imageUrl === undefined) {
      this.litterAnnouncement.imageUrl = '/assets/img/Logo_Classic.png';
    }
  }

  openSireModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.dogService.getDogById(this.litterAnnouncement.sireId.toString())
      .subscribe((response) => {
        this.sireModel = response;
      });
  }

  openDamModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.dogService.getDogById(this.litterAnnouncement.damId.toString())
      .subscribe((response) => {
        this.damModel = response;
      });
  }

  openImageGalleryModal(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.mediaService.getImageGalleryById(this.litterAnnouncement.imageGalleryId.toString())
      .subscribe((result) => {
        if (result) {
          this.galleryImageSlides = result.carouselImages;
        }
      });
  }

  openDeleteConfirmationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md, modal-dialog-centered' });
  }

  openDeleteReferenceWarningModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md, modal-dialog-centered' });
  }

  deleteLitterAnnouncement() {
    this.litterAnnouncementService.removeLitterAnnouncement(this.litterAnnouncement.id.toString())
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Litter Announcement has been successfully removed!');
          this.modalRef.hide();
          this.removalEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to remove Litter Announcement!');
        }
      });
  }

}
