import { Component, OnInit, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';

const FACEBOOK_URL = environment.socialChannels.facebook.url;
const TWITTER_URL = environment.socialChannels.twitter.url;
const INSTAGRAM_URL = environment.socialChannels.instagram.url;
const YOUTUBE_URL = environment.socialChannels.youtube.url;

@Component({
  selector: 'app-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.css']
})
export class PublicFooterComponent implements OnInit {

  currentYear = (new Date()).getFullYear();

  facebookUrl = FACEBOOK_URL;
  twitterUrl = TWITTER_URL;
  instagramUrl = INSTAGRAM_URL;
  youtubeUrl = YOUTUBE_URL;

  constructor() { }

  ngOnInit() {
  }


}
