import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/shared/models/page-model';
import { GirlDog } from 'src/app/shared/models/girl-dog';
import { PageService } from 'src/app/shared/services/page.service';
import { DogService } from 'src/app/shared/services/dog.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';

@Component({
  selector: 'app-our-girls',
  templateUrl: './our-girls.component.html',
  styleUrls: ['./our-girls.component.css']
})
export class CmsOurGirlsComponent implements OnInit {

  pageId = '4';
  pageModel: PageModel;

  girlDogs: GirlDog[];
  girlDogsLoaded = false;

  constructor(
    private pageService: PageService,
    private dogService: DogService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.loadPageDetails();
    this.loadGirlDogs();
  }

  /**
* loads page object for high level page details
*/
  loadPageDetails() {
    this.pageService.getPageById(this.pageId)
      .subscribe((response) => {
        if (response) {
          this.pageModel = response;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load page details');
        }
      });
  }

  loadGirlDogs() {
    this.dogService.getAllGirlDogs()
      .subscribe((response) => {
        if (response) {
          this.girlDogs = response;
          this.girlDogsLoaded = true;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load trained dogs');
        }

      });
  }

}
