import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { GirlDog } from 'src/app/shared/models/girl-dog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Dog } from 'src/app/shared/models/dog';
import { DogService } from 'src/app/shared/services/dog.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';
import { MediaService } from 'src/app/shared/services/media.service';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-girl-dog',
  templateUrl: './girl-dog.component.html',
  styleUrls: ['./girl-dog.component.css']
})
export class GirlDogComponent implements OnInit {

  @Input() girlDog: GirlDog;
  @Input() cmsMode: boolean;
  @Output() removalEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('deleteFailureModal', { static: true }) deleteFailureModal: TemplateRef<any>;

  modalRef: BsModalRef;
  sireModel: Dog;
  damModel: Dog;
  deleteFeedback: string;
  deleteResults: string[];

  constructor(private modalService: BsModalService,
    private dogService: DogService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.verifyImageUrl();
  }

  verifyImageUrl() {
    if (!this.girlDog.imageUrl || this.girlDog.imageUrl === undefined) {
      this.girlDog.imageUrl = '/assets/img/NoImage.png';
    }
  }

  openSireModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.dogService.getDogById(this.girlDog.sireId.toString())
      .subscribe((response) => {
        this.sireModel = response;
      });
  }

  openDamModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.dogService.getDogById(this.girlDog.damId.toString())
      .subscribe((response) => {
        this.damModel = response;
      });
  }

  openDeleteConfirmationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md, modal-dialog-centered' });
  }

  openDeleteFailureModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg, modal-dialog-centered' });
  }

  deleteGirlDog() {
    this.dogService.removeGirlDog(this.girlDog.id.toString())
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Girl Dog has been successfully removed!');
          this.modalRef.hide();
          this.removalEvent.emit();
        }
      }, (altResponse) => {
        if (altResponse.status === 409) {
          this.modalRef.hide();
          this.deleteFeedback = altResponse.error.body.feedback;
          this.deleteResults = altResponse.error.body.results;
          this.openDeleteFailureModal(this.deleteFailureModal);
        } else {
          this.notificationService.invokeNotificaion('error', 'Unexpected error while attempted to Delete Girl Dog!');
        }
      });
  }

}
