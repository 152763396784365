import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviousRouteService } from 'src/app/shared/helpers/previous-route.service';
import { UxService } from 'src/app/shared/helpers/ux.service';

@Component({
  selector: 'app-add-dog',
  templateUrl: './add-dog.component.html',
  styleUrls: ['./add-dog.component.css']
})
export class AddDogComponent implements OnInit {

  dogType: string;
  dogTypeTitle: string;
  dogParentUri: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uxService: UxService
  ) { }

  ngOnInit() {
    this.uxService.smoothScrollToTop();
    this.setDogType();
  }

  setDogType() {
    this.dogType = this.route.snapshot.paramMap.get('type');

    switch (this.dogType) {
      case 'trained-dog':
        this.dogTypeTitle = 'Trained Dog';
        this.dogParentUri = 'trained-dogs';
        break;
      case 'stud-dog':
        this.dogTypeTitle = 'Stud Dog';
        this.dogParentUri = 'stud-dogs';
        break;
      case 'girl-dog':
        this.dogTypeTitle = 'Girl Dog';
        this.dogParentUri = 'our-girls';
        break;
    }
  }

  redirectToParent() {
    this.router.navigate([`/manage/content/${this.dogParentUri}`]);
  }
}
