// Core Dependencies
import { BrowserModule, Title, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';

// App Modules
import { AppRoutingModule } from './app-routing.module';
import {
  ModalModule,
  BsDropdownModule,
  CollapseModule,
  CarouselModule,
  ButtonsModule,
  TabsModule
} from 'ngx-bootstrap';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { EditorModule } from '@tinymce/tinymce-angular';

import { ToastrModule } from 'ngx-toastr';

import { NgxGalleryModule } from 'ngx-gallery';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface =
  environment.dropzoneWrapper;

import {
  RECAPTCHA_SETTINGS,
  RecaptchaLoaderService,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module,
  RECAPTCHA_V3_SITE_KEY
} from 'ng-recaptcha';

// Base App Component
import { AppComponent } from './app.component';

// Public Components
import { BrowseComponent } from './public/browse/browse.component';
import { HomeComponent } from './public/home/home.component';
import { ContactComponent } from './public/contact/contact.component';
import { OurGirlsComponent } from './public/our-girls/our-girls.component';
import { PuppiesComponent } from './public/puppies/puppies.component';
import { StudDogsComponent } from './public/stud-dogs/stud-dogs.component';
import { TrainedDogsComponent } from './public/trained-dogs/trained-dogs.component';
import { TrainingServicesComponent } from './public/training-services/training-services.component';
import { MailingListSubscribeComponent } from './public/subscriptions/mailing-list-subscribe/mailing-list-subscribe.component';
import { MailingListUnsubscribeComponent } from './public/subscriptions/mailing-list-unsubscribe/mailing-list-unsubscribe.component';

// CMS Components
import { ManageComponent } from './cms/manage/manage.component';
import { CmsContactComponent } from './cms/content/contact/contact.component';
import { CmsHomeComponent } from './cms/content/home/home.component';
import { CmsAboutComponent } from './cms/content/about/about.component';
import { CmsOurGirlsComponent } from './cms/content/our-girls/our-girls.component';
import { CmsPuppiesComponent } from './cms/content/puppies/puppies.component';
import { AddLitterAnnouncementComponent } from './cms/content/add-litter-announcement/add-litter-announcement.component';
import { UpdateLitterAnnouncementComponent } from './cms/content/update-litter-announcement/update-litter-announcement.component';
import { CmsStudDogsComponent } from './cms/content/stud-dogs/stud-dogs.component';
import { CmsTrainedDogsComponent } from './cms/content/trained-dogs/trained-dogs.component';
import { AddDogComponent } from './cms/content/add-dog/add-dog.component';
import { UpdateDogComponent } from './cms/content/update-dog/update-dog.component';
import { CmsTrainingServicesComponent } from './cms/content/training-services/training-services.component';
import { MediaObjectsComponent } from './cms/media/media-objects/media-objects.component';
import { ImageGalleriesComponent } from './cms/media/image-galleries/image-galleries.component';
import { AccountManagementComponent } from './cms/users/account-management/account-management.component';
import { AddImageGalleryComponent } from './cms/media/add-image-gallery/add-image-gallery.component';
import { UpdateImageGalleryComponent } from './cms/media/update-image-gallery/update-image-gallery.component';

// Top level components
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { LoginComponent } from './authentication/login/login.component';
import { LogoutComponent } from './authentication/logout/logout.component';

// Shared Components
import { PublicNavComponent } from './shared/components/public-nav/public-nav.component';
import { PublicFooterComponent } from './shared/components/public-footer/public-footer.component';
import { CmsNavComponent } from './shared/components/cms-nav/cms-nav.component';
import { CmsSidebarComponent } from './shared/components/cms-sidebar/cms-sidebar.component';
import { ImageGalleryCarouselComponent } from './shared/components/media/image-gallery-carousel/image-gallery-carousel.component';
import { LitterAnnouncementComponent } from './shared/components/content/litter-announcement/litter-announcement.component';
import { DogDetailsComponent } from './shared/components/content/dog-details/dog-details.component';
import { TrainedDogComponent } from './shared/components/content/trained-dog/trained-dog.component';
import { StudDogComponent } from './shared/components/content/stud-dog/stud-dog.component';
import { FileManagerComponent } from './shared/components/media/file-manager/file-manager.component';
import { ImageGalleryManagerComponent } from './shared/components/media/image-gallery-manager/image-gallery-manager.component';
import {
  ImageGalleryCrudInterfaceComponent
} from './shared/components/media/image-gallery-crud-interface/image-gallery-crud-interface.component';
import { ImageSliderGhostComponent } from './shared/components/ghosts/image-slider-ghost/image-slider-ghost.component';
import { HeaderCardComponent } from './shared/components/header-card/header-card.component';
import { OverviewCardComponent } from './shared/components/overview-card/overview-card.component';
import { HeaderCardGhostComponent } from './shared/components/ghosts/header-card-ghost/header-card-ghost.component';
import { OverviewCardGhostComponent } from './shared/components/ghosts/overview-card-ghost/overview-card-ghost.component';
import { DogPostGhostComponent } from './shared/components/ghosts/dog-post-ghost/dog-post-ghost.component';
import { DogPostEditorComponent } from './shared/components/dog-post-editor/dog-post-editor.component';
import { LitterAnnouncementEditorComponent } from './shared/components/litter-announcement-editor/litter-announcement-editor.component';
import { AboutCardComponent } from './shared/components/about-card/about-card.component';
import { AboutComponent } from './public/about/about.component';
import { GirlDogComponent } from './shared/components/content/girl-dog/girl-dog.component';
import { MailingListComponent } from './cms/users/subscriptions/mailing-list/mailing-list.component';

// Services
import { ContactService } from './shared/services/contact.service';
import { HomeService } from './shared/services/home.service';
import { AboutService } from './shared/services/about.service';
import { DashboardComponent } from './cms/dashboard/dashboard.component';
import { AuthService } from './shared/services/auth.service';
import { MediaService } from './shared/services/media.service';
import { PageService } from './shared/services/page.service';
import { LitterAnnouncementService } from './shared/services/litter-announcement.service';
import { DogService } from './shared/services/dog.service';

// Helper Services
import { NotificationService } from './shared/helpers/notification.service';
import { UxService } from './shared/helpers/ux.service';
import { PreviousRouteService } from './shared/helpers/previous-route.service';
import { AppCacheService } from './shared/helpers/app-cache.service';

// Interceptors
import { interceptorProviders } from './shared/interceptors/interceptors';

// Guards
import { AuthGuard } from './shared/guards/auth.guard';
import { CommonModule, DatePipe } from '@angular/common';

// Pipes
import { SanitizeHtmlPipe } from './shared/pipes/sanitize-html.pipe';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';

// Directives
import { DisabledRfControlDirective } from './shared/directives/disabled-rf-control.directive';
import { UnknownErrorComponent } from './errors/unknown-error/unknown-error.component';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    BrowseComponent,
    HomeComponent,
    PageNotFoundComponent,
    LoginComponent,
    LogoutComponent,
    AccountManagementComponent,
    ContactComponent,
    AboutComponent,
    OurGirlsComponent,
    PuppiesComponent,
    StudDogsComponent,
    TrainedDogsComponent,
    ManageComponent,
    LoginComponent,
    PublicNavComponent,
    PublicFooterComponent,
    ManageComponent,
    CmsContactComponent,
    CmsHomeComponent,
    CmsAboutComponent,
    CmsOurGirlsComponent,
    CmsPuppiesComponent,
    CmsStudDogsComponent,
    CmsTrainedDogsComponent,
    CmsTrainingServicesComponent,
    MediaObjectsComponent,
    ImageGalleriesComponent,
    AccountManagementComponent,
    DashboardComponent,
    CmsNavComponent,
    CmsSidebarComponent,
    LitterAnnouncementComponent,
    DogDetailsComponent,
    TrainedDogComponent,
    StudDogComponent,
    GirlDogComponent,
    TrainingServicesComponent,
    ImageGalleryCarouselComponent,
    FileManagerComponent,
    ImageGalleryManagerComponent,
    AddImageGalleryComponent,
    ImageGalleryCrudInterfaceComponent,
    UpdateImageGalleryComponent,
    HeaderCardComponent,
    OverviewCardComponent,
    SanitizeHtmlPipe,
    SafeUrlPipe,
    HeaderCardGhostComponent,
    OverviewCardGhostComponent,
    DogPostGhostComponent,
    DogPostEditorComponent,
    LitterAnnouncementEditorComponent,
    AboutCardComponent,
    AddLitterAnnouncementComponent,
    UpdateLitterAnnouncementComponent,
    AddDogComponent,
    UpdateDogComponent,
    DisabledRfControlDirective,
    ImageSliderGhostComponent,
    UnknownErrorComponent,
    MailingListComponent,
    MailingListSubscribeComponent,
    MailingListUnsubscribeComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    DropzoneModule,
    RecaptchaModule,
    NgxGalleryModule,
    ContentLoaderModule,
    EditorModule
  ],
  providers: [
    Title,
    ModalModule,
    BsDropdownModule,
    AuthService,
    ContactService,
    HomeService,
    AboutService,
    MediaService,
    PageService,
    DogService,
    LitterAnnouncementService,
    NotificationService,
    UxService,
    PreviousRouteService,
    AppCacheService,
    AuthGuard,
    DatePipe,
    interceptorProviders,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
