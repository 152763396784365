import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mailing-list-unsubscribe',
  templateUrl: './mailing-list-unsubscribe.component.html',
  styleUrls: ['./mailing-list-unsubscribe.component.css']
})
export class MailingListUnsubscribeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
