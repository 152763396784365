import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ImageGalleryModel } from 'src/app/shared/models/image-gallery-model';
import { MediaService } from 'src/app/shared/services/media.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { CarouselImageModel } from 'src/app/shared/models/carousel-image-model';

@Component({
  selector: 'app-image-gallery-crud-interface',
  templateUrl: './image-gallery-crud-interface.component.html',
  styleUrls: ['./image-gallery-crud-interface.component.css']
})
export class ImageGalleryCrudInterfaceComponent implements OnInit {

  // Outputs
  @Output() completionEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelledEvent: EventEmitter<any> = new EventEmitter();

  // Optional input of existing image gallery
  @Input() id?: string;
  existingGallery: boolean;

  // Image Gallery
  imageGallery: ImageGalleryModel;
  galleryImagesVerified = false;
  galleryLoadedHasImages: boolean;
  galleryLoadedHasNoImages: boolean;

  // Individule Image
  selectedImage: number;
  stagedImage: CarouselImageModel;
  imageUrlsSet = false;

  modalRef: BsModalRef;

  constructor(
    private mediaService: MediaService,
    private notificationService: NotificationService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.imageGallery = new ImageGalleryModel();
    this.imageGallery.carouselImages = [];

    this.existingGallery = this.id && this.id !== undefined;

    // Look for an existing image gallery?
    if (this.existingGallery) {
      this.loadExistingGallery(this.id);
    }

    // Have gallery images been verified?
    if (!this.galleryImagesVerified) {
      this.verifyGalleryImages();
    }

  }

  /**
   * Loads and assigns an existing gallery to the imageGallery object
   * @param id
   */
  loadExistingGallery(id: string) {
    this.mediaService.
      getImageGalleryById(id)
      .subscribe((gallery) => {
        this.imageGallery = gallery;
        this.verifyGalleryImages();
      });
  }

  /**
   * Verifies the existance of image within the gallery
   */
  verifyGalleryImages() {
    this.galleryLoadedHasImages =
      this.imageGallery &&
      this.imageGallery.carouselImages &&
      this.imageGallery.carouselImages.length !== 0;

    this.galleryLoadedHasNoImages =
      this.imageGallery &&
      this.imageGallery.carouselImages &&
      this.imageGallery.carouselImages.length === 0;

    this.galleryImagesVerified = true;
  }

  /**
   * ADD IMAGE MODAL - ON OPEN
   * @param modal
   */
  openAddImageModal(modal: TemplateRef<any>) {
    this.stagedImage = new CarouselImageModel;
    this.stagedImage.selected = false;
    this.modalRef = this.modalService.show(modal, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  /**
   * ADD IMAGE MODAL - ON SAVE
   */
  onAddImageModalSave() {
    this.addImage();
    this.stagedImage = new CarouselImageModel;
    this.imageUrlsSet = false;
    this.modalRef.hide();
  }

  /**
   * ADD IMAGE MODAL - ON CANCEL
   */
  onAddImageModalCancel() {
    this.stagedImage = new CarouselImageModel;
    this.imageUrlsSet = false;
    this.modalRef.hide();
  }

  /**
   * Adds a new image to image gallery
   * Assumes this.stagedImage has been initialized prior to call
   */
  addImage() {

    // assign image id from random generator
    this.stagedImage.id = this.getRandomId();

    // assign gallery id if image is being added to an existing gallery
    if (this.existingGallery) { this.stagedImage.imageGalleryId = this.imageGallery.id; }

    // does new image now have an id assigned?
    if (this.stagedImage.id && this.stagedImage.id !== undefined && this.stagedImage.id !== 0) {

      // add to array
      this.imageGallery.carouselImages.push(this.stagedImage);
      this.verifyGalleryImages();

      // notify user
      this.notificationService.invokeNotificaion('success', 'Image successfully added');
    }
  }

  /**
   * Returns a random geneated Id to be assigned to a new image
   * in a scenerio where image is added to a new gallery
   */
  getRandomId(): number {

    // assign temperary id
    const min = 1;
    const max = 2147483647;
    const random = Math.floor(Math.random() * (+ max - + min)) + + min;
    return random;
  }

  /**
   * Saves images passed in via API and returns id, returned from API
   * @param image
   */
  addImageToExistingGallery(image: CarouselImageModel): number {

    // add image via API
    this.mediaService.createCarouselImage(image)
      .subscribe((response) => {
        if (response.responseBody.feedback === 'Success') {
          return response.responseBody.id;
        } else {
          // report error
          this.notificationService.invokeNotificaion('error', 'There was an error while attempting to add the image');
        }
      });

    return 0;
  }

  /**
   * UPDATE IMAGE MODAL - ON OPEN
   * @param modal
   */
  openUpdateImageModal(modal: TemplateRef<any>) {
    this.stagedImage = this.imageGallery.carouselImages.find(img => img.id === this.selectedImage);
    if (this.stagedImage.url && this.stagedImage.small && this.stagedImage.medium && this.stagedImage.big) { this.imageUrlsSet = true; }
    this.modalRef = this.modalService.show(modal, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  /**
   * UPDATE IMAGE MODAL - ON SAVE
   */
  onUpdateImageModalSave() {
    this.updateImage();
    this.stagedImage = new CarouselImageModel;
    this.imageGallery.carouselImages.find(img => img.id === this.selectedImage).selected = false;
    this.selectedImage = undefined;
    this.imageUrlsSet = false;
    this.modalRef.hide();
  }

  /**
   * UPDATE IMAGE MODAL - ON CANCEL
   */
  onUpdateImageModalCancel() {
    this.stagedImage = new CarouselImageModel;
    this.imageGallery.carouselImages.find(img => img.id === this.selectedImage).selected = false;
    this.selectedImage = undefined;
    this.imageUrlsSet = false;
    this.modalRef.hide();
  }

  /**
   * When used, updates an image via the API
   */
  updateImage() {

    // replace the corresponding image in gallery with new version
    let existingImage = this.imageGallery.carouselImages.find(img => img.id === this.stagedImage.id);
    existingImage = this.stagedImage;
    this.notificationService.invokeNotificaion('success', 'Image has been successfully updated');

    // Invoke updateImageInExistingGallery() if we ever needed to update images via API in real time
  }

  /**
   * Updates carousel image via API if it already belongs to an existing image gallery
   */
  updateImageInExistingGallery() {
    this.mediaService.updateCarouselImage(this.stagedImage)
      .subscribe((response) => {
        if (response.responseBody.feedback === 'Success') {
          // success
          this.notificationService.invokeNotificaion('success', 'Image has been successfully updated');
        } else {
          // failure
          this.notificationService.invokeNotificaion('error', 'There was an error while attempting to delete the image');
        }
      });
  }

  /**
   * DELETE IMAGE MODAL - ON OPEN
   * @param modal
   */
  openRemoveImageModal(modal: TemplateRef<any>) {
    this.stagedImage = this.imageGallery.carouselImages.find(img => img.id === this.selectedImage);
    this.modalRef = this.modalService.show(modal, { class: 'modal-md', ignoreBackdropClick: true });
  }

  /**
   * REMOVE IMAGE MODAL - ON SAVE
   */
  onRemoveImageModalSave() {
    this.removeImage();
    this.stagedImage = new CarouselImageModel;
    this.modalRef.hide();
  }

  /**
   * REMOVE IMAGE MODAL - ON CANCEL
   */
  onRemoveImageModalCancel() {
    this.stagedImage = new CarouselImageModel;
    this.imageGallery.carouselImages.find(img => img.id === this.selectedImage).selected = false;
    this.selectedImage = undefined;
    this.modalRef.hide();
  }

  /**
   * Removes an image from the gallery,
   * posting (DELETE) to the API only if the gallery exists in the database
   */
  removeImage() {
    const existingImage = this.imageGallery.carouselImages.find(img => img.id === this.stagedImage.id);
    this.imageGallery.carouselImages = this.imageGallery.carouselImages.filter(img => img !== existingImage);
    this.notificationService.invokeNotificaion('success', 'Image has been successfully deleted');

    // Invoke removeImageInExistingGallery() if we every needed to remove images via API in real time
  }

  /**
   * When used, removes an image via the API
   */
  removeImageInExistingGallery() {
    this.mediaService.removeCarouselImage(this.stagedImage.id.toString())
      .subscribe((response) => {
        if (response.responseBody.feedback === 'Success') {
          // success
          this.notificationService.invokeNotificaion('success', 'Image has been successfully deleted');
        } else {
          // failure
          this.notificationService.invokeNotificaion('error', 'There was an error while attempting to delete the image');
        }
      });
  }

  /**
   * Handles process of updating existing or saving new gallery
   * handles any additional tasks after data transaction
   */
  saveImageGallery() {
    // EXISTING GALLERY
    if (this.existingGallery) {
      this.updateExistingGallery();

      // NEW GALLERY
    } else {
      // Remove temp ids from images
      this.imageGallery.carouselImages.forEach(img => img.id = undefined);
      this.saveNewGallery();
    }
  }

  /**
   * Updates an existing image gallery via API
   */
  updateExistingGallery() {
    this.mediaService.updateImageGallery(this.imageGallery)
      .subscribe((response) => {
        if (response.responseBody.feedback === 'Success') {
          // report succces
          this.notificationService.invokeNotificaion('success', 'Image gallery has been added successfully added.');
          // emit completion event
          this.completionEvent.emit();
        } else {
          // report failure
          this.notificationService.invokeNotificaion('error', 'An error has occurred while attempting to save the image gallery.');
        }
      });
  }

  /**
   * Saves a new image gallery via API
   */
  saveNewGallery() {
    this.mediaService.createImageGallery(this.imageGallery)
      .subscribe((response) => {
        if (response.responseBody.feedback === 'Success') {
          // report success
          this.notificationService.invokeNotificaion('success', 'Image gallery has been added successfully added.');
          // emit completion event
          this.completionEvent.emit();
        } else {
          // report failure
          this.notificationService.invokeNotificaion('error', 'An error has occurred while attempting to save the image gallery.');
        }
      });
  }

  /**
   * Handles events during a cancel scenerio
   */
  cancelImageGallery() {
    this.cancelledEvent.emit();
  }

  setStagedImageUrls(url: string) {
    this.stagedImage.url = url;
    this.stagedImage.big = url;
    this.stagedImage.medium = url;
    this.stagedImage.small = url;
    this.imageUrlsSet = true;
  }

  clearStagedImageUrls() {
    this.stagedImage.url = null;
    this.stagedImage.big = null;
    this.stagedImage.medium = null;
    this.stagedImage.small = null;
    this.imageUrlsSet = false;
  }

  selectImage(id: number) {

    const target = this.imageGallery.carouselImages.find(img => img.id === id);

    if (target) {
      const unselected = this.imageGallery.carouselImages.filter(img => img !== target);
      unselected.forEach(img => { img.selected = false; });
    }

    if (target.selected) {
      this.selectedImage = id;
    } else {
      this.selectedImage = undefined;
      target.selected = undefined;
    }
  }

}
