import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';



import { HttpClient, HttpParams } from '@angular/common/http';
import { LitterAnnouncement } from '../models/litter-announcement';

const API_URL = environment.apiUrl;
const litterAnnouncementsUrl = `${API_URL}/litter-announcements`;

@Injectable({
  providedIn: 'root'
})
export class LitterAnnouncementService {

  constructor(private http: HttpClient) { }

  /**
   * Retrieves a single litter announcement based on the db assigned identifyer
   * @param id
   */
  public getLitterAnnouncementById(id: string): Observable<LitterAnnouncement> {
    const params = new HttpParams().set('id', id);
    return this.http.get<LitterAnnouncement>(litterAnnouncementsUrl, { params });
  }

  /**
   * Retrieves all public litter announcements
   */
  public getAllPublicLitterAnnouncements(): Observable<LitterAnnouncement[]> {
    const params = new HttpParams().set('public', 'true');
    return this.http.get<LitterAnnouncement[]>(litterAnnouncementsUrl, { params });
  }

  /**
 * Retrieves all litter announcements
 * @param id
 */
  public getAllLitterAnnouncements(): Observable<LitterAnnouncement[]> {
    return this.http.get<LitterAnnouncement[]>(litterAnnouncementsUrl);
  }

  /**
   * Adds a single litter announcement
   * @param model
   */
  public addLitterAnnouncement(model: LitterAnnouncement): Observable<any> {
    return this.http.post<LitterAnnouncement>(litterAnnouncementsUrl, model);
  }

  /**
  * Updates a litter announcement
  * @param model
  */
  public updateLitterAnnouncement(model: any): Observable<any> {
    return this.http.put<LitterAnnouncement>(litterAnnouncementsUrl, model);
  }

  /**
  * Removes an existing Litter Announcement
  */
  public removeLitterAnnouncement(id: string): Observable<any> {
    const param = new HttpParams().set('id', id);
    return this.http.delete<LitterAnnouncement>(litterAnnouncementsUrl, { params: param });
  }

}
