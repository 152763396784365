import { Directive, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisabledRfControl]'
})
export class DisabledRfControlDirective {

  @Input() set appDisabledRfControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';

    // Disabled controls
    this.ngControl.control[action]();

    // Reset control value if diabled
    if (condition) {
      this.ngControl.reset();
    }
  }

  constructor(private ngControl: NgControl) { }

}
