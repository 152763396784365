import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';



import { HttpClient, HttpParams } from '@angular/common/http';
import { DirectoryListingModel } from '../models/directory-listing-model';
import { RenameMediaModel } from '../models/rename-media-model';
import { AddFolderModel } from '../models/add-folder-model';
import { ImageGalleryModel } from '../models/image-gallery-model';
import { CarouselImageModel } from '../models/carousel-image-model';

const API_URL = environment.apiUrl;
const imageGalleriesUrl = `${API_URL}/media/image-galleries`;
const carouselImagesUrl = `${API_URL}/media/carousel-images`;

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }

  /**
  * Gets all media content for the given directory and optional path passed in
  * @param directory
  */
  public getDirectoryListing(directory: string): Observable<DirectoryListingModel> {
    const params = new HttpParams()
      .set('directory', directory);
    return this.http.get<DirectoryListingModel>(API_URL + '/media', { params: params });
  }

  /**
  * Deletes a media item using the path of the item
  * @param path
  */
  public deleteItem(path: string): Observable<any> {
    const pathParam = new HttpParams()
      .set('path', path);
    return this.http.delete<string>(API_URL + '/media', { params: pathParam });
  }

  /**
  * Renames an existing media item using the current file path/name and the new file path/name
  * @param model
  */
  public renameMedia(model: RenameMediaModel): Observable<any> {
    return this.http.post<RenameMediaModel>(API_URL + '/media/rename', model);
  }

  /**
  * Creates a new folder using the folder path and name in the model
  * @param model
  */
  public createFolder(model: AddFolderModel): Observable<any> {
    return this.http.post<AddFolderModel>(API_URL + '/media/folder', model);
  }

  /**
  * Gets all image galleries, returning ImageGalleryModel[]
  */
  public getAllImageGalleries(): Observable<ImageGalleryModel[]> {
    return this.http.get<ImageGalleryModel[]>(imageGalleriesUrl);
  }

  /**
  * Gets a single image gallery by id, returning ImageGalleryModel
  */
  public getImageGalleryById(id: string): Observable<ImageGalleryModel> {
    const params = new HttpParams().set('id', id);
    return this.http.get<ImageGalleryModel>(imageGalleriesUrl, { params: params });
  }

  /**
  * Creates a new Image Gallery using the data passed in through the model
  * @param model
  */
  public createImageGallery(model: ImageGalleryModel): Observable<any> {
    return this.http.post<ImageGalleryModel>(imageGalleriesUrl, model);
  }

  /**
  * Updates an existing Image Gallery
  * @param model
  */
  public updateImageGallery(model: ImageGalleryModel): Observable<any> {
    return this.http.put<ImageGalleryModel>(imageGalleriesUrl, model);
  }

  /**
  * Removes an existing Image Gallery
  */
  public removeImageGallery(imageGalleryId: string): Observable<any> {
    const imageGalleryIdParam = new HttpParams().set('id', imageGalleryId);
    return this.http.delete<ImageGalleryModel>(imageGalleriesUrl, { params: imageGalleryIdParam });
  }

  /**
  * Creates a new Carousel Image using the data passed in through the model
  * @param model
  */
  public createCarouselImage(model: CarouselImageModel): Observable<any> {
    return this.http.post<CarouselImageModel>(carouselImagesUrl, model);
  }

  /**
  * Updates an existing Carousel Image
  * @param model
  */
  public updateCarouselImage(model: CarouselImageModel): Observable<any> {
    return this.http.put<CarouselImageModel>(carouselImagesUrl, model);
  }

  /**
  * Removes an existing Carousel Image
  */
  public removeCarouselImage(carouselImageId: string): Observable<any> {
    const carouselImageIdParam = new HttpParams().set('id', carouselImageId);
    return this.http.delete<ImageGalleryModel>(carouselImagesUrl, { params: carouselImageIdParam });
  }

}
