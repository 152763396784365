import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { StudDog } from 'src/app/shared/models/stud-dog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Dog } from 'src/app/shared/models/dog';
import { CarouselImageModel } from 'src/app/shared/models/carousel-image-model';
import { DogService } from 'src/app/shared/services/dog.service';
import { MediaService } from 'src/app/shared/services/media.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';

@Component({
  selector: 'app-stud-dog',
  templateUrl: './stud-dog.component.html',
  styleUrls: ['./stud-dog.component.css']
})
export class StudDogComponent implements OnInit {

  @Input() studDog: StudDog;
  @Input() cmsMode: boolean;
  @Output() removalEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('deleteFailureModal', { static: true }) deleteFailureModal: TemplateRef<any>;

  modalRef: BsModalRef;

  sireModel: Dog;
  damModel: Dog;
  galleryImageSlides: CarouselImageModel[];
  gallerySlideInterval = 5000;
  galleryActiveSlideIndex = 0;

  postEmailSubject: string;
  postEmailBody: string;
  postEmailBodyEncoded: string;
  postEmailHyperlink: string;

  deleteFeedback: string;
  deleteResults: string[];

  constructor(
    private modalService: BsModalService,
    private dogService: DogService,
    private mediaService: MediaService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.verifyImageUrl();

    this.postEmailSubject = encodeURI(this.studDog.fullName);

    this.postEmailBody = `Hello, I am inquiring about the availability of the stud dog from your post ` +
      `titled "${this.studDog.fullName}"`;

    this.postEmailBodyEncoded = encodeURI(this.postEmailBody);

    this.postEmailHyperlink = `mailto:danny.littlejohn@moccasincreek.net?` +
      `subject=${this.postEmailSubject}&body=${this.postEmailBodyEncoded}`;
  }

  verifyImageUrl() {
    if (!this.studDog.imageUrl || this.studDog.imageUrl === undefined) {
      this.studDog.imageUrl = '/assets/img/NoImage.png';
    }
  }

  openSireModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.dogService.getDogById(this.studDog.sireId.toString())
      .subscribe((response) => {
        this.sireModel = response;
      });
  }

  openDamModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.dogService.getDogById(this.studDog.damId.toString())
      .subscribe((response) => {
        this.damModel = response;
      });
  }

  openImageGalleryModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.mediaService.getImageGalleryById(this.studDog.imageGalleryId.toString())
      .subscribe((result) => {
        if (result) {
          this.galleryImageSlides = result.carouselImages;
        }
      });
  }

  openDeleteConfirmationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md, modal-dialog-centered' });
  }

  openDeleteFailureModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg, modal-dialog-centered' });
  }

  deleteStudDog() {
    this.dogService.removeStudDog(this.studDog.id.toString())
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Stud Dog has been successfully removed!');
          this.modalRef.hide();
          this.removalEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to remove Stud Dog!');
        }
      }, (altResponse) => {
        if (altResponse.status === 409) {
          this.modalRef.hide();
          this.deleteFeedback = altResponse.error.body.feedback;
          this.deleteResults = altResponse.error.body.results;
          this.openDeleteFailureModal(this.deleteFailureModal);
        } else {
          this.notificationService.invokeNotificaion('error', 'Unexpected error while attempted to Delete Stud Dog!');
        }
      });
  }

}
