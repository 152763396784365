import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-image-gallery',
  templateUrl: './add-image-gallery.component.html',
  styleUrls: ['./add-image-gallery.component.css']
})
export class AddImageGalleryComponent implements OnInit {


  constructor(private router: Router) { }

  ngOnInit() {

  }

  redirectToManager() {
    // redirect to image gallery manager
    this.router.navigate(['/manage/media/image-galleries']);
  }

}
