import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { PageModel } from 'src/app/shared/models/page-model';
import { PageService } from 'src/app/shared/services/page.service';
import { SeoService } from 'src/app/shared/helpers/seo.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class AboutComponent implements OnInit {

  page: PageModel;
  pageId: string;
  pageTitle = 'About Us';

  constructor(
    private titleService: Title,
    private pageService: PageService,
    private seoService: SeoService
  ) { }

  ngOnInit() {

    // Set Page title
    this.setTitle();

    // Assign page id for service
    this.pageId = '5';

    // Get page details
    this.pageService
      .getPageById(this.pageId)
      .subscribe((pg) => {
        this.page = pg;

        const metaData = {
          keywordsMeta: 'our mission, guarantee information, payment information, shipping information',
          authorMeta: 'Moccasin Creek Kennel - Longview, TX',
          descriptionMeta: this.page.headerCardSubTitle,
          ogTitleMeta: this.pageTitle,
          ogDescriptionMeta: this.page.headerCardSubTitle,
          ogUrlMeta: window.location.href
        };

        this.seoService.setMetaTags(metaData);
        this.seoService.setCononicalUrl(window.location.href);
      });

  }

  setTitle() {
    const basePageTitle = environment.basePageTitle;
    const currentPageTitle = this.pageTitle;
    this.titleService.setTitle(`${currentPageTitle}${basePageTitle}`);
  }

}
