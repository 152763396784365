export class PageModel {
    id: number;
    headerCardTitle?: string;
    headerCardSubTitle?: string;
    overviewCardTitle?: string;
    overviewCardContent?: any;
    youtubeContent?: boolean;
    youtubeContentUrl?: string;

}
