import { Component, OnInit } from '@angular/core';
import { UxService } from '../../helpers/ux.service';
import { environment } from 'src/environments/environment';

const FACEBOOK_URL = environment.socialChannels.facebook.url;
const TWITTER_URL = environment.socialChannels.twitter.url;
const INSTAGRAM_URL = environment.socialChannels.instagram.url;
const YOUTUBE_URL = environment.socialChannels.youtube.url;

@Component({
  selector: 'app-public-nav',
  templateUrl: './public-nav.component.html',
  styleUrls: ['./public-nav.component.css']
})
export class PublicNavComponent implements OnInit {

  facebookUrl = FACEBOOK_URL;
  twitterUrl = TWITTER_URL;
  instagramUrl = INSTAGRAM_URL;
  youtubeUrl = YOUTUBE_URL;

  isCollapsed = true;

  constructor(private uxService: UxService) { }

  ngOnInit() {
  }

  navigate() {
    this.isCollapsed = true;
    this.uxService.smoothScrollToTop();
  }

}
