import { Component, OnInit, Input } from '@angular/core';
import { PageModel } from '../../models/page-model';
import { PageService } from '../../services/page.service';
import { NotificationService } from '../../helpers/notification.service';
import { environment } from 'src/environments/environment';

const TINY_MCE = environment.tinyMce;

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.css']
})
export class OverviewCardComponent implements OnInit {
  @Input() cmsMode: boolean;
  @Input() pageModel: PageModel;
  isYouTubeCollapsed = true;
  youtubeContentSaveBtnEnabled = false;
  tinyMce;

  constructor(
    private pageService: PageService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    if (TINY_MCE) {
      this.tinyMce = TINY_MCE;
    } else {
      this.notificationService.invokeNotificaion('error', 'U');
    }
  }

  /**
   * Updated Overview card title
   * @param overviewCardTitle
   */
  saveOverviewCardTitle(overviewCardTitle: string) {
    // Patch model
    const cardPatch = {
      id: this.pageModel.id,
      overviewCardTitle: overviewCardTitle
    };

    this.updatePage(cardPatch);
  }

  /**
   * Updates Overvied card content html
   * @param overviewCardContent
   */
  saveOverviewCardContent(event: any) {
    // Patch model
    const cardPatch = {
      id: this.pageModel.id,
      overviewCardContent: this.pageModel.overviewCardContent
    };

    this.updatePage(cardPatch);
  }

  /**
   * Events to take place when checkbox for Enable Youtube Content is checked or unchecked
   */
  onEnableYoutubeContentChange() {
    // Enable save button since the checkbox value has been modified
    this.youtubeContentSaveBtnEnabled = true;
  }

  /**
   * Updates Overview card youtube content setup
   * @param youtubeContent
   */
  setYoutubeContent() {
    // Patch model
    const cardPatch = {
      id: this.pageModel.id,
      youtubeContent: this.pageModel.youtubeContent,
      youtubeContentUrl: this.pageModel.youtubeContentUrl
    };

    this.updatePage(cardPatch);
  }

  updatePage(model: any) {
    this.pageService.updatePage(model).subscribe(response => {
      if (response.responseBody.feedback === 'Success') {
        this.notificationService.invokeNotificaion(
          'success',
          'Page Overview Card has been successfully updated'
        );
      } else {
        this.notificationService.invokeNotificaion(
          'error',
          'There was an error while attempting to update page Overview Card details'
        );
      }
    });
  }
}
