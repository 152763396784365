import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-services',
  templateUrl: './training-services.component.html',
  styleUrls: ['./training-services.component.css']
})
export class CmsTrainingServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
