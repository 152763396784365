import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UxService } from '../../helpers/ux.service';

@Component({
    selector: 'app-cms-sidebar',
    templateUrl: './cms-sidebar.component.html',
    styleUrls: ['./cms-sidebar.component.scss']
})
export class CmsSidebarComponent {
    isActive = false;
    showMenu = '';
    pushRightClass = 'push-right';

    constructor(public router: Router, private uxService: UxService) {

        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }

    eventCalled() {
        this.isActive = !this.isActive;
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        localStorage.removeItem('isLoggedin');
    }

    navigate() {
        this.uxService.smoothScrollToTop();
    }
}
