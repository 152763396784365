import { Component, OnInit } from '@angular/core';
import { LitterAnnouncement } from '../../shared/models/litter-announcement';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { PageModel } from 'src/app/shared/models/page-model';
import { PageService } from 'src/app/shared/services/page.service';
import { LitterAnnouncementService } from 'src/app/shared/services/litter-announcement.service';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { SeoService } from 'src/app/shared/helpers/seo.service';

@Component({
  selector: 'app-puppies',
  templateUrl: './puppies.component.html',
  styleUrls: ['./puppies.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class PuppiesComponent implements OnInit {

  pageId = '1';
  pageModel: PageModel;

  litterAnnouncements: LitterAnnouncement[];
  litterAnnouncementsLoaded = false;

  constructor(
    private titleService: Title,
    private pageService: PageService,
    private litterAnnouncementService: LitterAnnouncementService,
    private seoService: SeoService) { }

  ngOnInit() {

    this.setTitle();
    this.loadPageDetails();
    this.loadLitterAnnouncements();
  }

  setTitle() {
    const basePageTitle = environment.basePageTitle;
    const currentPageTitle = 'Litter Announcements';
    this.titleService.setTitle(`${currentPageTitle}${basePageTitle}`);
  }

  /**
  * loads page object for high level page details
  */
  loadPageDetails() {

    this.pageService.getPageById(this.pageId)
      .subscribe((response) => {
        this.pageModel = response;

        const metaData = {
          keywordsMeta: `${this.pageModel.headerCardSubTitle}`,
          authorMeta: 'Moccasin Creek Kennel - Longview, TX',
          descriptionMeta: this.pageModel.headerCardSubTitle,
          ogTitleMeta: this.pageModel.headerCardSubTitle,
          ogDescriptionMeta: this.pageModel.headerCardSubTitle,
          ogUrlMeta: window.location.href
        };

        this.seoService.setMetaTags(metaData);
        this.seoService.setCononicalUrl(window.location.href);

      });

  }

  loadLitterAnnouncements() {
    this.litterAnnouncementService.getAllPublicLitterAnnouncements()
      .subscribe((response) => {
        if (response) {
          this.litterAnnouncements = response;
          this.litterAnnouncementsLoaded = true;
        } else {
          // TODO: redirect to custom error page
        }
      });
  }


}
