import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UxService } from 'src/app/shared/helpers/ux.service';

@Component({
  selector: 'app-update-litter-announcement',
  templateUrl: './update-litter-announcement.component.html',
  styleUrls: ['./update-litter-announcement.component.css']
})
export class UpdateLitterAnnouncementComponent implements OnInit {

  litterAnnouncementId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uxService: UxService
  ) { }

  ngOnInit() {
    this.uxService.smoothScrollToTop();
    this.litterAnnouncementId = this.route.snapshot.paramMap.get('id');
  }

  redirectToParent() {
    this.router.navigate(['/manage/content/puppies']);
  }

}
