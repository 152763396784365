import { Component, OnInit, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { ImageGalleryModel } from '../../models/image-gallery-model';
import { Dog } from '../../models/dog';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TrainedDog } from '../../models/trained-dog';
import { StudDog } from '../../models/stud-dog';
import { GirlDog } from '../../models/girl-dog';
import { DogService } from '../../services/dog.service';
import { MediaService } from '../../services/media.service';
import { UxService } from '../../helpers/ux.service';
import { NotificationService } from '../../helpers/notification.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dog-post-editor',
  templateUrl: './dog-post-editor.component.html',
  styleUrls: ['./dog-post-editor.component.css']
})
export class DogPostEditorComponent implements OnInit {

  // Outputs
  @Output() completionEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelledEvent: EventEmitter<any> = new EventEmitter();

  // Required input Dog Type
  @Input() dogType: string;

  // Optional input id, for existing dog (id of derived dog, not base)
  @Input() id?: string;
  existingDog: boolean;

  // Dog
  dog: any;

  requiredFieldsTabActive = true;
  optionalFieldsTabActive = false;
  postImageTabActive = false;

  imageGalleryOptions: ImageGalleryModel[];
  imageGallerySelectPlaceholder = 'Loading Image Galleries...';

  dogReferenceOptions: Dog[];
  dogReferenceOptionselectPlaceholder = 'Loading Reference Dogs...';

  invalidFieldsOnSubmit = false;

  dogForm = new FormGroup({
    noImageGalleryChoice: new FormControl('', { updateOn: 'change' }),
    fullName: new FormControl('', Validators.required),
    callName: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    color: new FormControl('', Validators.required),
    owner: new FormControl('', Validators.required),
    breeder: new FormControl(''),
    coatGenotype: new FormControl(''),
    pedigreeUrl: new FormControl('', Validators.required),
    title: new FormControl(''),
    ftChampionPoints: new FormControl(''),
    derbyPoints: new FormControl(''),
    imageGallery: new FormControl(''),
    litterDue: new FormControl(''),
    whelpDate: new FormControl(''),
    ofaHips: new FormControl(''),
    ofaElbow: new FormControl(''),
    eyeCerf: new FormControl(''),
    cnm: new FormControl(''),
    eic: new FormControl(''),
    akc: new FormControl(''),
    akcReg: new FormControl(''),
    akcDna: new FormControl(''),
    ukcReg: new FormControl(''),
    osd: new FormControl(''),
    pra: new FormControl(''),
    weight: new FormControl(''),
    sire: new FormControl(''),
    noSireChoice: new FormControl('', { updateOn: 'change' }),
    dam: new FormControl(''),
    noDamChoice: new FormControl('', { updateOn: 'change' }),
    noImageChoice: new FormControl('', { updateOn: 'change' })
  }, { updateOn: 'blur' });

  dogFormFullName = this.dogForm.get('fullName');
  dogFormCallName = this.dogForm.get('callName');
  dogFormGender = this.dogForm.get('gender');
  dogFormColor = this.dogForm.get('color');
  dogFormTitle = this.dogForm.get('title');
  dogFormOwner = this.dogForm.get('owner');
  dogFormBreeder = this.dogForm.get('breeder');
  dogFormPedigreeUrl = this.dogForm.get('pedigreeUrl');

  dogFormWhelpDate = this.dogForm.get('whelpDate');
  dogFormCoatGenotype = this.dogForm.get('coatGenotype');
  dogFormFChampionPoints = this.dogForm.get('ftChampionPoints');
  dogFormDerbyPoints = this.dogForm.get('derbyPoints');
  dogFormOfaHips = this.dogForm.get('ofaHips');
  dogFormOfaElbow = this.dogForm.get('ofaElbow');
  dogFormEyeCerf = this.dogForm.get('eyeCerf');
  dogFormCnm = this.dogForm.get('cnm');
  dogFormEic = this.dogForm.get('eic');
  dogFormAkc = this.dogForm.get('akc');
  dogFormAkcReg = this.dogForm.get('akcReg');
  dogFormAkcDna = this.dogForm.get('akcDna');
  dogFormUkcReg = this.dogForm.get('ukcReg');
  dogFormOsd = this.dogForm.get('osd');
  dogFormPra = this.dogForm.get('pra');
  dogFormWeight = this.dogForm.get('weight');

  dogFormImageGallery = this.dogForm.get('imageGallery');
  dogFormNoImageGalleryChoice = this.dogForm.get('noImageGalleryChoice');
  dogFormSire = this.dogForm.get('sire');
  dogFormNoSireChoice = this.dogForm.get('noSireChoice');
  dogFormDam = this.dogForm.get('dam');
  dogFormNoDamChoice = this.dogForm.get('noDamChoice');
  dogFormNoImageChoice = this.dogForm.get('noImageChoice');

  trainedDogFields;
  studDogFields;
  girlDogFields;

  modalRef: BsModalRef;

  constructor(
    private dogService: DogService,
    private mediaService: MediaService,
    private uxService: UxService,
    private notificationService: NotificationService,
    private modalService: BsModalService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.setDerivedDogType();
    this.dog.imageUrl = '/assets/img/NoImage.png';
    this.existingDog = this.id && this.id !== undefined;
    this.loadImageGalleryOptions();
    this.loadReferenceDogOptions();
    if (this.existingDog) {
      this.loadExistingDog();
    }
  }

  selectTab(tab: string) {
    switch (tab) {
      case 'requiredFields':
        this.requiredFieldsTabActive = true;
        this.optionalFieldsTabActive = false;
        this.postImageTabActive = false;
        break;
      case 'optionalFields':
        this.requiredFieldsTabActive = false;
        this.optionalFieldsTabActive = true;
        this.postImageTabActive = false;
        this.invalidFieldsOnSubmit = false;
        break;
      case 'postImage':
        this.requiredFieldsTabActive = false;
        this.optionalFieldsTabActive = false;
        this.postImageTabActive = true;
        this.invalidFieldsOnSubmit = false;
        break;
    }
  }

  setDerivedDogType() {

    // What is the derived type?
    switch (this.dogType) {
      case 'trained-dog':
        this.dog = new TrainedDog;
        this.addTrainedDogsFields();
        break;
      case 'stud-dog':
        this.dog = new StudDog;
        this.addStudDogsFields();
        break;
      case 'girl-dog':
        this.dog = new GirlDog;
        this.addGirlDogsFields();
    }

  }

  addTrainedDogsFields() {
    this.dogForm.addControl('trainingLevel', new FormControl('', Validators.required));
    this.dogForm.addControl('price', new FormControl('', Validators.required));
    this.dogForm.addControl('deposit', new FormControl(''));
    this.dogForm.addControl('guarantee', new FormControl(''));

    this.trainedDogFields = {
      dogFormTrainingLevel: this.dogForm.get('trainingLevel'),
      dogFormPrice: this.dogForm.get('price'),
      dogFormDeposit: this.dogForm.get('deposit'),
      dogFormGuarantee: this.dogForm.get('guarantee')
    };

  }

  addStudDogsFields() {
    this.dogForm.addControl('studFee', new FormControl('', Validators.required));

    this.studDogFields = {
      dogFormStudFee: this.dogForm.get('studFee')
    };
  }

  addGirlDogsFields() {

  }

  loadExistingDog() {
    // What type of dog are we loading?
    if (this.dogType === 'trained-dog') {
      this.dogService
        .getTrainedDogById(this.id)
        .subscribe((result) => {
          this.dog = result;
          this.mapModelToForm();
        });
    }

    if (this.dogType === 'stud-dog') {
      this.dogService
        .getStudDogById(this.id)
        .subscribe((result) => {
          this.dog = result;
          this.mapModelToForm();
        });
    }

    if (this.dogType === 'girl-dog') {
      this.dogService
        .getGirlDogById(this.id)
        .subscribe((result) => {
          this.dog = result;
          this.mapModelToForm();
        });
    }
  }

  loadImageGalleryOptions() {
    this.mediaService
      .getAllImageGalleries()
      .subscribe((response) => {
        this.imageGalleryOptions = response;
        this.imageGallerySelectPlaceholder = '--Choose an Image Gallery--';
      });
  }

  loadReferenceDogOptions() {
    this.dogService
      .getAllReferenceDogs()
      .subscribe((response) => {
        this.dogReferenceOptions = response;
        this.dogReferenceOptionselectPlaceholder = '--Choose a dog--';
      });

  }

  toggleNoImageChoice() {
    if (this.dogFormNoImageChoice.value === true) {
      this.clearImageSelection();
    }
  }

  clearImageSelection() {
    this.dog.imageUrl = '/assets/img/NoImage.png';
  }

  openImageSelectModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  setImageUrl(url: string) {
    this.dog.imageUrl = url;
    this.closeModal();
  }

  closeModal() {
    this.modalRef.hide();
  }

  saveButtonClick() {
    this.uxService.markFormGroupTouched(this.dogForm);

    // Is the form valid?
    if (this.dogForm.invalid) {
      this.invalidFieldsOnSubmit = true;
      this.selectTab('requiredFields');
      this.uxService.smoothScrollToTop();
      return;
    }

    this.mapFormToModel();

    // Save new or existing and emit status
    if (this.existingDog) {
      switch (this.dogType) {
        case 'trained-dog':
          this.updateTrainedDog();
          break;
        case 'stud-dog':
          this.updateStudDog();
          break;
        case 'girl-dog':
          this.updateGirlDog();
          break;
      }
    } else {
      switch (this.dogType) {
        case 'trained-dog':
          this.addTrainedDog();
          break;
        case 'stud-dog':
          this.addStudDog();
          break;
        case 'girl-dog':
          this.addGirlDog();
          break;
      }
    }

  }

  addStudDog() {
    this.dogService.addStudDog(this.dog)
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Stud dog has been successfully added!');
          this.completionEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to add Stud dog!');
        }
      });
  }

  updateStudDog() {
    this.dogService.updateStudDog(this.dog)
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Stud dog has been successfully updated!');
          this.completionEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to update Stud dog!');
        }
      });
  }

  addTrainedDog() {
    this.dogService.addTrainedDog(this.dog)
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Trained dog has been successfully added!');
          this.completionEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to add Trained dog!');
        }
      });
  }

  updateTrainedDog() {
    this.dogService.updateTrainedDog(this.dog)
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Trained dog has been successfully updated!');
          this.completionEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to update Trained dog!');
        }
      });
  }

  addGirlDog() {
    this.dogService.addGirlDog(this.dog)
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Girl dog has been successfully added!');
          this.completionEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to add Girl dog!');
        }
      });
  }

  updateGirlDog() {
    this.dogService.updateGirlDog(this.dog)
      .subscribe((response) => {
        if (response && response.responseBody.feedback === 'Success') {
          this.notificationService.invokeNotificaion('success', 'Girl dog has been successfully updated!');
          this.completionEvent.emit();
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to update Girl dog!');
        }
      });
  }

  mapModelToForm() {
    this.dogForm.controls['fullName'].setValue(this.dog.fullName);
    this.dogForm.controls['callName'].setValue(this.dog.callName);
    this.dogForm.controls['gender'].setValue(this.dog.gender);
    this.dogForm.controls['color'].setValue(this.dog.color);
    this.dogForm.controls['owner'].setValue(this.dog.owner);
    this.dogForm.controls['breeder'].setValue(this.dog.breeder);
    this.dogForm.controls['pedigreeUrl'].setValue(this.dog.pedigreeUrl);

    if (this.dogType === 'trained-dog') {
      this.dogForm.controls['trainingLevel'].setValue(this.dog.trainingLevel);
      this.dogForm.controls['price'].setValue(this.dog.price);
      this.dogForm.controls['deposit'].setValue(this.dog.deposit);
      this.dogForm.controls['guarantee'].setValue(this.dog.guarantee);
    }

    if (this.dogType === 'stud-dog') {
      this.dogForm.controls['studFee'].setValue(this.dog.studFee);
    }

    if (this.dogType === 'girl-dog') { }

    this.dogForm.controls['whelpDate'].setValue(this.dog.whelpDate);
    this.dogForm.controls['coatGenotype'].setValue(this.dog.coatGenotype);
    this.dogForm.controls['title'].setValue(this.dog.title);
    this.dogForm.controls['ftChampionPoints'].setValue(this.dog.ftChampionPoints);
    this.dogForm.controls['derbyPoints'].setValue(this.dog.derbyPoints);
    this.dogForm.controls['ofaHips'].setValue(this.dog.ofaHips);
    this.dogForm.controls['ofaElbow'].setValue(this.dog.ofaElbow);
    this.dogForm.controls['eyeCerf'].setValue(this.dog.eyeCerf);
    this.dogForm.controls['cnm'].setValue(this.dog.cnm);
    this.dogForm.controls['eic'].setValue(this.dog.eic);
    this.dogForm.controls['akc'].setValue(this.dog.akc);
    this.dogForm.controls['akcReg'].setValue(this.dog.akcReg);
    this.dogForm.controls['akcDna'].setValue(this.dog.akcDna);
    this.dogForm.controls['ukcReg'].setValue(this.dog.ukcReg);
    this.dogForm.controls['osd'].setValue(this.dog.osd);
    this.dogForm.controls['pra'].setValue(this.dog.pra);
    this.dogForm.controls['weight'].setValue(this.dog.weight);

    if (this.dog.imageGalleryId !== undefined && this.dog.imageGalleryId !== null) {
      this.dogForm.controls['noImageGalleryChoice'].setValue(false);
      this.dogForm.controls['imageGallery'].setValue(this.dog.imageGalleryId);
    } else {
      this.dogForm.controls['noImageGalleryChoice'].setValue(true);
    }

    if (this.dog.sireId !== undefined && this.dog.sireId !== null) {
      this.dogForm.controls['noSireChoice'].setValue(false);
      this.dogForm.controls['sire'].setValue(this.dog.sireId);
    } else {
      this.dogForm.controls['noSireChoice'].setValue(true);
    }

    if (this.dog.damId !== undefined && this.dog.damId !== null) {
      this.dogForm.controls['noDamChoice'].setValue(false);
      this.dogForm.controls['dam'].setValue(this.dog.damId);
    } else {
      this.dogForm.controls['noDamChoice'].setValue(true);
    }

    if (this.dog.whelpDate !== undefined && this.dog.whelpDate !== null) {
      const whelpDateFormatted = this.datePipe.transform(new Date(this.dog.whelpDate), 'yyyy-MM-dd');
      this.dogForm.controls['whelpDate'].setValue(whelpDateFormatted);
    }

    if (this.dog.imageUrl !== '/assets/img/NoImage.png') {
      this.dogForm.controls['noImageChoice'].setValue(false);
    } else {
      this.dogForm.controls['noImageChoice'].setValue(true);
    }

  }

  mapFormToModel() {
    this.dog.fullName = this.dogFormFullName.value;
    this.dog.callName = this.dogFormCallName.value;
    this.dog.gender = this.dogFormGender.value;
    this.dog.color = this.dogFormColor.value;
    this.dog.owner = this.dogFormOwner.value;
    this.dog.breeder = this.dogFormBreeder.value;
    this.dog.pedigreeUrl = this.dogFormPedigreeUrl.value;

    if (this.dogType === 'trained-dog') {
      this.dog.trainingLevel = this.trainedDogFields.dogFormTrainingLevel.value;
      this.dog.price = this.trainedDogFields.dogFormPrice.value;

      if (this.trainedDogFields.dogFormDeposit.value !== null &&
        this.trainedDogFields.dogFormDeposit.value !== undefined &&
        this.trainedDogFields.dogFormDeposit.value !== '') {
        this.dog.deposit = this.trainedDogFields.dogFormDeposit.value;
      } else {
        this.dog.deposit = null;
      }

      this.dog.guarantee = this.trainedDogFields.dogFormGuarantee.value;
    }

    if (this.dogType === 'stud-dog') {
      this.dog.studFee = this.studDogFields.dogFormStudFee.value;
    }

    if (this.dogType === 'girl-dog') { }


    this.dog.coatGenotype = this.dogFormCoatGenotype.value;
    this.dog.title = this.dogFormTitle.value;
    this.dog.ftChampionPoints = this.dogFormFChampionPoints.value;
    this.dog.derbyPoints = this.dogFormDerbyPoints.value;
    this.dog.ofaHips = this.dogFormOfaHips.value;
    this.dog.ofaElbow = this.dogFormOfaElbow.value;
    this.dog.eyeCerf = this.dogFormEyeCerf.value;
    this.dog.cnm = this.dogFormCnm.value;
    this.dog.eic = this.dogFormEic.value;
    this.dog.akc = this.dogFormAkc.value;
    this.dog.akcReg = this.dogFormAkcReg.value;
    this.dog.akcDna = this.dogFormAkcDna.value;
    this.dog.ukcReg = this.dogFormUkcReg.value;
    this.dog.osd = this.dogFormOsd.value;
    this.dog.pra = this.dogFormPra.value;
    this.dog.weight = this.dogFormWeight.value;

    // Value or null for the following fields
    if (this.dogFormWhelpDate.value !== null &&
      this.dogFormWhelpDate.value !== undefined &&
      this.dogFormWhelpDate.value !== '') {
      this.dog.whelpDate = this.dogFormWhelpDate.value;
    } else {
      this.dog.whelpDate = null;
    }


    if (this.dogFormNoImageGalleryChoice.value === true ||
      this.dogFormImageGallery.value === null ||
      this.dogFormImageGallery.value === undefined ||
      this.dogFormImageGallery.value === '') {
      this.dog.imageGalleryId = null;
    } else {
      this.dog.imageGalleryId = this.dogFormImageGallery.value;
    }

    if (this.dogFormNoSireChoice.value === true ||
      (this.dogFormSire.value === null ||
        this.dogFormSire.value === undefined ||
        this.dogFormSire.value === '')) {
      this.dog.sireId = null;
    } else {
      this.dog.sireId = this.dogFormSire.value;
    }

    if (this.dogFormNoDamChoice.value === true ||
      (this.dogFormDam.value === null ||
        this.dogFormDam.value === undefined ||
        this.dogFormDam.value === '')) {
      this.dog.damId = null;
    } else {
      this.dog.damId = this.dogFormDam.value;
    }

  }

  cancelButtonClick() {
    // FUTURE: implement 'Are you sure' notification
    this.cancelledEvent.emit();
  }

}
