import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-cms-nav',
    templateUrl: './cms-nav.component.html',
    styleUrls: ['./cms-nav.component.scss']
})
export class CmsNavComponent implements OnInit {

    @Input() userFullName: string;
    @Output() navLogout: EventEmitter<any> = new EventEmitter();

    pushRightClass = 'push-right';

    constructor(public router: Router) {

        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() { }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onNavLogout() {
        this.navLogout.emit();
    }
}
