import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactFormModel } from 'src/app/shared/models/contact-form-model';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { ContactService } from 'src/app/shared/services/contact.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription, ObservableInput, Observable } from 'rxjs';
import { RecaptchaSiteverifyResponse } from 'src/app/shared/models/recaptcha-siteverify-response';
import { GoogleApiService } from 'src/app/shared/services/google-api.service';
import { RecaptchaSiteverifyRequest } from 'src/app/shared/models/recaptcha-siteverify-request';
import { PageModel } from 'src/app/shared/models/page-model';
import { PageService } from 'src/app/shared/services/page.service';
import { SeoService } from 'src/app/shared/helpers/seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class ContactComponent implements OnInit {

  page: PageModel;
  pageId: string;
  pageTitle = 'Contact Us';

  contactFormModel = new ContactFormModel;
  contactForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    emailAddress: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    phoneNumber: new FormControl('', Validators.required),
    feedback: new FormControl('', Validators.required)
  }, { updateOn: 'blur' });

  contactFormFirstName = this.contactForm.get('firstName');
  contactFormLastName = this.contactForm.get('lastName');
  contactFormEmailAddress = this.contactForm.get('emailAddress');
  contactFormPhoneNumber = this.contactForm.get('phoneNumber');
  contactFormFeedback = this.contactForm.get('feedback');
  contactFormSubmitted = false;
  contactFormSubscribed = false;
  contactFormSubmitError = false;
  recaptchaValid = false;

  constructor(
    private titleService: Title,
    private contactService: ContactService,
    private pageService: PageService,
    private seoService: SeoService
  ) { }

  ngOnInit() {

    // Set Page title
    this.setTitle();

    // Assign page id for service
    this.pageId = '6';

    // Get page details
    this.pageService
      .getPageById(this.pageId)
      .subscribe((pg) => {
        this.page = pg;

        const metaData = {
          keywordsMeta: 'contact us',
          authorMeta: 'Moccasin Creek Kennel - Longview, TX',
          descriptionMeta: this.page.headerCardSubTitle,
          ogTitleMeta: this.pageTitle,
          ogDescriptionMeta: this.page.headerCardSubTitle,
          ogUrlMeta: window.location.href
        };

        this.seoService.setMetaTags(metaData);
        this.seoService.setCononicalUrl(window.location.href);
      });
  }

  onContactFormSubmit() {
    this.contactFormSubmitted = true;

    this.contactFormModel.firstName = this.contactFormFirstName.value;
    this.contactFormModel.lastName = this.contactFormLastName.value;
    this.contactFormModel.emailAddress = this.contactFormEmailAddress.value;
    this.contactFormModel.phoneNumber = this.contactFormPhoneNumber.value;
    this.contactFormModel.feedback = this.contactFormFeedback.value;

    this.contactService.submitContactForm(this.contactFormModel)
      .subscribe(
        (result) => {
          this.contactFormSubscribed = true;
          if (!result.body.feedback) {
            this.contactFormSubmitError = true;
          }
          this.contactForm.reset();
        }
      );
  }

  dismissFormNotification() {
    this.contactFormSubmitted = false;
    this.contactFormSubscribed = false;
    this.contactFormSubmitError = false;
  }

  onRecaptchaResolved(e) {
    this.recaptchaValid = true;
  }

  setTitle() {
    const basePageTitle = environment.basePageTitle;
    const currentPageTitle = this.pageTitle;
    this.titleService.setTitle(`${currentPageTitle}${basePageTitle}`);
  }

}
