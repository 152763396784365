import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ContactFormModel } from '../models/contact-form-model';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;
const contactUrl = `${API_URL}/communications/contact-form`;

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  /**
 * Submits a contact form
 * @param contactForm
 */
  public submitContactForm(contactForm: ContactFormModel): Observable<any> {
    return this.http.post<ContactFormModel>(contactUrl, contactForm);
  }

}
