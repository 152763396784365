import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/shared/models/page-model';
import { TrainedDog } from 'src/app/shared/models/trained-dog';
import { PageService } from 'src/app/shared/services/page.service';
import { DogService } from 'src/app/shared/services/dog.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';

@Component({
  selector: 'app-trained-dogs',
  templateUrl: './trained-dogs.component.html',
  styleUrls: ['./trained-dogs.component.css']
})
export class CmsTrainedDogsComponent implements OnInit {

  pageId = '2';
  pageModel: PageModel;

  trainedDogs: TrainedDog[];
  trainedDogsLoaded = false;

  constructor(
    private pageService: PageService,
    private dogService: DogService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.loadPageDetails();
    this.loadTrainedDogs();
  }

  /**
  * loads page object for high level page details
  */
  loadPageDetails() {
    this.pageService.getPageById(this.pageId)
      .subscribe((response) => {
        if (response) {
          this.pageModel = response;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load page details');
        }
      });
  }

  loadTrainedDogs() {
    this.dogService.getAllTrainedDogs()
      .subscribe((response) => {
        if (response) {
          this.trainedDogs = response;
          this.trainedDogsLoaded = true;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load trained dogs');
        }

      });
  }

}
