import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/shared/models/page-model';
import { StudDog } from 'src/app/shared/models/stud-dog';
import { PageService } from 'src/app/shared/services/page.service';
import { DogService } from 'src/app/shared/services/dog.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';

@Component({
  selector: 'app-stud-dogs',
  templateUrl: './stud-dogs.component.html',
  styleUrls: ['./stud-dogs.component.css']
})
export class CmsStudDogsComponent implements OnInit {


  pageId = '3';
  pageModel: PageModel;

  studDogs: StudDog[];
  studDogsLoaded = false;

  constructor(
    private pageService: PageService,
    private dogService: DogService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.loadPageDetails();
    this.loadStudDogs();
  }

  /**
* loads page object for high level page details
*/
  loadPageDetails() {
    this.pageService.getPageById(this.pageId)
      .subscribe((response) => {
        if (response) {
          this.pageModel = response;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load page details');
        }
      });
  }

  loadStudDogs() {
    this.dogService.getAllStudDogs()
      .subscribe((response) => {
        if (response) {
          this.studDogs = response;
          this.studDogsLoaded = true;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load trained dogs');
        }

      });
  }

}
