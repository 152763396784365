import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UxService } from 'src/app/shared/helpers/ux.service';

@Component({
  selector: 'app-update-dog',
  templateUrl: './update-dog.component.html',
  styleUrls: ['./update-dog.component.css']
})
export class UpdateDogComponent implements OnInit {

  dogType: string;
  dogTypeTitle: string;
  dogParentUri: string;
  dogId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uxService: UxService
  ) { }

  ngOnInit() {
    this.uxService.smoothScrollToTop();
    this.dogId = this.route.snapshot.paramMap.get('id');
    this.setDogType();
  }

  setDogType() {
    this.dogType = this.route.snapshot.paramMap.get('type');

    switch (this.dogType) {
      case 'trained-dog':
        this.dogTypeTitle = 'Trained Dog';
        this.dogParentUri = 'trained-dogs';
        break;
      case 'stud-dog':
        this.dogTypeTitle = 'Stud Dog';
        this.dogParentUri = 'stud-dogs';
        break;
      case 'girl-dog':
        this.dogTypeTitle = 'Girl Dog';
        this.dogParentUri = 'our-girls';
        break;
    }
  }

  redirectToParent() {
    this.router.navigate([`/manage/content/${this.dogParentUri}`]);
  }

}
