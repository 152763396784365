import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {

  userFullName;

  constructor(
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    await this.authService.getTokens()
      .then(async (res) => {
        let idToken = res.tokens.idToken;
        let accessToken = res.tokens.accessToken;
        await this.authService.getUserClaims(idToken, accessToken)
          .then((userResponse) => {
            this.userFullName = userResponse.name;
          });
      });
  }

  async logout() {
    this.authService.logout('/');
  }

}
