import { Component, OnInit } from '@angular/core';
import { GirlDog } from 'src/app/shared/models/girl-dog';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { DogService } from 'src/app/shared/services/dog.service';
import { PageModel } from 'src/app/shared/models/page-model';
import { PageService } from 'src/app/shared/services/page.service';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { SeoService } from 'src/app/shared/helpers/seo.service';

@Component({
  selector: 'app-our-girls',
  templateUrl: './our-girls.component.html',
  styleUrls: ['./our-girls.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class OurGirlsComponent implements OnInit {

  pageId = '4';
  pageModel: PageModel;

  girlDogs: GirlDog[];
  girlDogsLoaded = false;

  constructor(
    private titleService: Title,
    private pageService: PageService,
    private dogsService: DogService,
    private seoService: SeoService
  ) { }

  ngOnInit() {
    this.setTitle();
    this.loadPageDetails();
    this.loadGirlDogs();
  }

  loadPageDetails() {
    this.pageService.getPageById(this.pageId)
      .subscribe((response) => {

        this.pageModel = response;

        const metaData = {
          keywordsMeta: this.pageModel.headerCardSubTitle,
          authorMeta: 'Moccasin Creek Kennel - Longview, TX',
          descriptionMeta: this.pageModel.headerCardSubTitle,
          ogTitleMeta: this.pageModel.headerCardSubTitle,
          ogDescriptionMeta: this.pageModel.headerCardSubTitle,
          ogUrlMeta: window.location.href
        };

        this.seoService.setMetaTags(metaData);
        this.seoService.setCononicalUrl(window.location.href);

      });
  }

  loadGirlDogs() {
    this.dogsService.getAllPublicGirlDogs()
      .subscribe((response) => {
        if (response) {
          this.girlDogs = response;
          this.girlDogsLoaded = true;
        } else {
          // TODO: redirect to custom error page
        }
      });
  }

  setTitle() {
    const basePageTitle = environment.basePageTitle;
    const currentPageTitle = 'Our Girls';
    this.titleService.setTitle(`${currentPageTitle}${basePageTitle}`);
  }

}
