import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(tap(
                event => { },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        switch (error.status) {

                            // 401 Unauthorized
                            case 401:
                                this.handle401Error();
                                break;

                            // 500 Internal Server Error
                            case 500:
                                this.handle500Error();
                                break;
                        }
                    }
                }
            ));
    }

    /**
     * Handles the http response error code 401 - Unauthorized
     */
    private handle401Error() {
        this.authService.logout('/');
        this.router.navigate(['/login']);
    }

    /**
      * Handles the http response error code 500 - Internal Server Error
      */
    private handle500Error() {
        this.router.navigate(['/unknown-error']);
    }

}
