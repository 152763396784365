export class Dog {
    dogId = 0;
    fullName: string;
    title: string;
    ftChampionPoints: string;
    derbyPoints: string;
    callName: string;
    imageUrl: string;
    imageGalleryId: number;
    pedigreeUrl: string;
    whelpDate: string;
    gender: string;
    color: string;
    coatGenotype: string;
    owner: string;
    breeder: string;
    ofaHips: string;
    ofaElbow: string;
    eyeCerf: string;
    cnm: string;
    eic: string;
    akc: string;
    akcReg: string;
    akcDna: string;
    ukcReg: string;
    osd: string;
    pra: string;
    weight: string;
    sireId: number;
    sireTitle: string;
    damId: number;
    damTitle: string;
    public: boolean;
    availableAsReference: boolean;
}
