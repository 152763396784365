import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { UxService } from 'src/app/shared/helpers/ux.service';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { SeoService } from 'src/app/shared/helpers/seo.service';
import { PageService } from 'src/app/shared/services/page.service';
import { PageModel } from 'src/app/shared/models/page-model';

const FACEBOOK_URL = environment.socialChannels.facebook.url;
const TWITTER_URL = environment.socialChannels.twitter.url;
const INSTAGRAM_URL = environment.socialChannels.instagram.url;
const YOUTUBE_URL = environment.socialChannels.youtube.url;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class HomeComponent implements OnInit {

  pageId = '8';
  pageModel: PageModel;

  facebookUrl = FACEBOOK_URL;
  twitterUrl = TWITTER_URL;
  instagramUrl = INSTAGRAM_URL;
  youtubeUrl = YOUTUBE_URL;

  isCollapsed = true;

  constructor(
    private titleService: Title,
    private uxService: UxService,
    private pageService: PageService,
    private seoService: SeoService
  ) { }

  ngOnInit() {
    this.setTitle();
    this.loadPageDetails();
  }

  loadPageDetails() {
    this.pageService.getPageById(this.pageId)
      .subscribe((response) => {

        this.pageModel = response;

        const metaData = {
          keywordsMeta: this.pageModel.headerCardSubTitle,
          authorMeta: 'Moccasin Creek Kennel - Longview, TX',
          descriptionMeta: this.pageModel.headerCardSubTitle,
          ogTitleMeta: this.pageModel.headerCardSubTitle,
          ogDescriptionMeta: this.pageModel.headerCardSubTitle,
          ogUrlMeta: window.location.href
        };

        this.seoService.setMetaTags(metaData);
        this.seoService.setCononicalUrl(window.location.href);

      });
  }

  setTitle() {
    const basePageTitle = environment.basePageTitle;
    const currentPageTitle = 'Labrador Retriever Breeding and Training';
    this.titleService.setTitle(`${currentPageTitle}${basePageTitle}`);
  }

  scrollToSummary(el: HTMLElement) {
    this.uxService.scrollToElement(el);
  }

  navigate() {
    this.isCollapsed = true;
    this.uxService.smoothScrollToTop();
  }

}
