import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/shared/models/page-model';
import { PageService } from 'src/app/shared/services/page.service';
import { NotificationService } from 'src/app/shared/helpers/notification.service';
import { LitterAnnouncement } from 'src/app/shared/models/litter-announcement';
import { LitterAnnouncementService } from 'src/app/shared/services/litter-announcement.service';

@Component({
  selector: 'app-puppies',
  templateUrl: './puppies.component.html',
  styleUrls: ['./puppies.component.css']
})
export class CmsPuppiesComponent implements OnInit {

  pageId = '1';
  pageModel: PageModel;

  litterAnnouncements: LitterAnnouncement[];
  litterAnnouncementsLoaded = false;

  constructor(
    private pageService: PageService,
    private litterAnnouncementService: LitterAnnouncementService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.loadPageDetails();
    this.loadLitterAnnouncements();
  }

  /**
   * loads page object for high level page details
   */
  loadPageDetails() {
    this.pageService.getPageById(this.pageId)
      .subscribe((response) => {
        if (response) {
          this.pageModel = response;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load page details');
        }
      });
  }

  loadLitterAnnouncements() {
    this.litterAnnouncementService.getAllLitterAnnouncements()
      .subscribe((response) => {
        if (response) {
          this.litterAnnouncements = response;
          this.litterAnnouncementsLoaded = true;
        } else {
          this.notificationService.invokeNotificaion('error', 'Unable to load litter announcements');
        }
      });
  }
}
